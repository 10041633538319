import toast from "react-hot-toast";
import { connect } from "react-redux";
import { merge } from "../../utils/tw";
import { funnyNumberValidation, scaleNum, truncate } from "../../utils/string";
import DuelCard from "../DuelCard/DuelCard";
import { Fragment, useEffect, useMemo, useState, useReducer } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { AnimatedSprite, Container, Stage } from "@pixi/react";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { API_URL, drops, getBatchImages } from "../../utils/api";

import moment from "moment";
import { Helmet } from "react-helmet";
import { useLocalStorage } from "../../App";

export function CoinflipModals({
  isAuthenticated,
  originalLocation,
  token,
  user,
  closeModal,
}: any) {
  const navigate = useNavigate();
  const location = useLocation();
  const [val, setValue] = useState(0);
  const [start, setStart] = useState(false);
  const [type, setType] = useState(location.state?.type ?? null);
  const [temp, setTemp] = useState<any>(null);
  const [showAnimation, setPlay] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [showValue, setShowValue] = useState(false);
  const [game, setGame] = useState<null | any>(null);
  const [history, setHistory] = useState<null | any[]>([]);
  const [alreadyJoined, setAlreadyJoined] = useState(false);
  const [id, setId] = useState((type === "view" && location.state?.id) ?? null);
  const [timeLeft, setTimeLeft] = useState<number | null>(null);
  const [option, setOption] = useState<"heads" | "tails">("heads");
  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  const [quickDebounce, setEnableCreate] = useState(false);

  const textures = useMemo(
    () =>
      Array.from(
        { length: 209 },
        (_, i: number) =>
          `sprites/old_TennoTails/${
            i < 10 ? `0${i}` : i
          }_TennoTailsSpritesheet.png`
      ),
    []
  );

  useEffect(() => {
    if (!isAuthenticated && type === "create")
      navigate("/login", {
        state: { background: originalLocation },
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  useEffect(() => {
    const fetchGame = async () => {
      const request = await fetch(`${API_URL}/coinflip/game/${id}`);

      if (request.status === 401)
        return toast.error((await request.json()).error, {
          position: "top-center",
        });

      if (request.status === 500)
        return toast.error("Something went wrong", {
          position: "top-center",
        });

      const response = await request.json();

      // const imageUrl = await getItemImage(response.itemId);

      if (["COMPLETED", "SHOW_GAME"].includes(response.status)) {
        setGame({
          players: response.players,
          itemId: response.itemId,
          itemName: response.itemName,
          price: response.price,
          reward: response.reward,
        });

        setPlay(true);
        setStart(false);
        setTemp({ ...response, imageUrl: response?.imageUrl ?? "" });
      } else setGame(response);
    };

    const fetchHistory = async () => {
      const request = await fetch(`${API_URL}/coinflip/history`);

      if (request.status === 401)
        return toast.error((await request.json()).error, {
          position: "top-center",
        });

      if (request.status === 500)
        return toast.error("Something went wrong", {
          position: "top-center",
        });

      const response = await request.json();

      const limIds = response.games.map((i: any) => i.itemId);

      const batchImages = await getBatchImages(limIds);

      setHistory(
        response.games.map((game: any) => ({
          imageUrl: batchImages.find((i: any) => i.id === game.itemId).uri,
          ...game,
        }))
      );
    };

    if (id) fetchGame();
    if (type === "history") fetchHistory();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let tm: any = undefined;

    if (location.state?.join && !alreadyJoined) {
      joinGame();
      setAlreadyJoined(true);
    }

    const gameUpdated = (updatedGame: any) => {
      if (updatedGame._id === id) {
        setGame((previousGame: any) => ({
          imageUrl: previousGame?.imageUrl ?? "",
          ...updatedGame,
        }));
        console.log(game);
      }
    };

    const gameFinished = (updatedGame: any) => {
      if (updatedGame._id === id) {
        // setTimeLeft(5);
        // setStart(true);
        // setGame((prev: any) => ({
        //   imageUrl: prev?.imageUrl ?? "",
        //   ...prev,
        //   players: updatedGame.players,
        // }));

        tm = setTimeout(() => {
          setPlay(true);
          setStart(false);
          setTemp({ ...updatedGame, imageUrl: game?.imageUrl ?? "" });
        }, 5000);

        // setGame((prev: any) => ({
        //   ...updatedGame,
        //   imageUrl: prev?.imageUrl ?? "",
        // }));
        // setShowValue(true);
      }
    };

    drops.on("updated-cf", gameUpdated);
    drops.on("finished-cf", gameFinished);

    return () => {
      clearTimeout(tm);

      drops.off("updated-cf", gameUpdated);
      drops.off("finished-cf", gameFinished);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!timeLeft) return;

    timeLeft > 0 && setTimeout(() => setTimeLeft(timeLeft - 1), 1000);

    if (timeLeft === 0) {
      setStart(false);
      setPlay(true);
    }
  }, [timeLeft]);

  const createGame = async () => {
    if (quickDebounce) return;
    setEnableCreate(true);

    if (!funnyNumberValidation(val) || val < 500)
      return toast.error("Amount must be greater than 500", {
        position: "top-right",
      });

    const request = await fetch(`${API_URL}/coinflip/create`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
      },
      body: JSON.stringify({
        betAmount: val,
        option: option === "heads" ? 0 : 1,
        type: "STRICT",
      }),
    });

    if (request.status === 403) {
      const response = await request.json();

      return toast.error(response.message, {
        position: "top-right",
      });
    } else if (request.status === 500)
      return toast.error("Something went wrong, please try again later.");

    // navigate("/duels");

    const newGame = await request.json();

    // setId(newGame._id)
    // setType("view")
    // setGame({ imageUrl: `https://adurite-images.onrender.com/images?assetId=${newGame.itemId}&width=150&height=150&format=Png`, ...newGame});

    // navigate(".", {
    //   state: {
    //     background: originalLocation,
    //     type: "view",
    //     id: newGame._id,
    //   },
    //   replace: true
    // });

    navigate(`/duels?id=${newGame._id}`, {
      replace: true,
    });

    setEnableCreate(true);

    // forceUpdate()

    // window.location.reload();

    return toast.success("Created Game", {
      position: "top-right",
    });
  };

  const joinGame = async () => {
    const request = await fetch(`${API_URL}/coinflip/${id}/update`, {
      method: "POST",
      headers: {
        Authorization: token,
      },
    });

    if (request.status === 401)
      return toast.error((await request.json()).error);
    if (request.status === 500) return toast.error("Something went wrong");

    return toast.success("Joined Game!");
  };

  const addBot = async () => {
    const request = await fetch(`${API_URL}/coinflip/${id}/update`, {
      method: "POST",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ addBot: true }),
    });

    if (request.status === 401)
      return toast.error((await request.json()).error);
    if (request.status === 500) return toast.error("Something went wrong");

    // navigate(`/duels?id=${id}`, {
    //   replace: true,
    // });

    // forceUpdate()

    // window.location.reload();

    return toast.success("Called bot!");
  };

  // if(reload) return <Redirect to={window.location.pathname} />;

  return type === "create" ? (
    <>
      <div className="mx-auto mt-[11%] flex w-[400px] h-[450px] flex-row self-center">
        <div
          className="flex h-full w-full flex-col space-y-10 rounded-[20px] bg-[#15141F] text-white"
          style={{
            boxShadow: "0px 4px 4px 0 rgba(0,0,0,0.25)",
          }}
        >
          <div className="space-y-2">
            <p className="pt-8 text-3xl font-bold text-center">
              Create Coinflip
            </p>
            <div className="flex flex-col items-center w-full h-full px-8 space-y-3">
              <div className="flex h-[150px] w-full items-center justify-center">
                <motion.img
                  className="w-36 h-36"
                  transition={{ delay: 1 }}
                  alt={option}
                  src={
                    option === "heads"
                      ? "/images/Heads.png"
                      : "/images/Tails.png"
                  }
                />
              </div>
              <div className="flex h-[50px] w-[100px] flex-row items-center justify-center rounded-lg bg-primarydark [&>button]:py-1.5 [&>button]:px-4 [&>button]:rounded-lg [&>button]:font-bold [&>button]:text-xl">
                <button
                  className={`py-1.5 px-4 rounded-lg font-bold text-xl ${
                    option === "heads"
                      ? "bg-lightblue hover:bg-[#60a5ff] blue-button"
                      : ""
                  }`}
                  onClick={() => setOption("heads")}
                >
                  H
                </button>
                <button
                  className={`py-1.5 px-4 rounded-lg font-bold text-xl ${
                    option === "tails"
                      ? "bg-lightblue hover:bg-[#60a5ff] blue-button"
                      : ""
                  }`}
                  onClick={() => setOption("tails")}
                >
                  T
                </button>
              </div>
              <form
                className="w-full space-y-2"
                onSubmit={(e) => {
                  e.preventDefault();
                  createGame();
                }}
              >
                <div className="flex h-[50px] flex-row items-center px-6 py-3 rounded-lg bg-primarydark">
                  <img
                    src="/images/currency.png"
                    alt="Robux"
                    className="inline-block w-4 h-4 mt-1 mr-2 align-middle"
                  />
                  <input
                    className="w-full text-lg text-right bg-primarydark"
                    value={val.toString()}
                    type="text"
                    onChange={(e) => funnyNumberValidation(e.target.value as any) ? setValue(Number(e.target.value)) : setValue(0)}
                    onPaste={(e) => funnyNumberValidation(e.clipboardData.getData('text/plain')) ? setValue(Number(e.clipboardData.getData('text/plain'))) : setValue(0)}
                  />
                </div>
                <div className="flex space-x-4">
                  <button
                    className="flex w-full items-center justify-between rounded-lg px-6 py-4 bg-lightblue hover:bg-[#60a5ff] blue-button"
                    type="submit"
                  >
                    <p className="text-base font-bold whitespace-nowrap">
                      CREATE DUEL
                    </p>
                    <div className="flex items-center space-x-1">
                      <img
                        src="/images/currency.png"
                        alt="Robux"
                        className="w-4 h-4"
                      />
                      <p
                        className="text-right"
                        style={{ fontSize: `clamp(0.75rem, 2.5vw, 1rem)` }}
                      >
                        {val.toLocaleString()}
                      </p>
                    </div>
                  </button>
                  <button
                    className="px-3 rounded-md bg-primary"
                    onClick={() => setValue(user.balance)}
                    type="button"
                  >
                    MAX
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  ) : type === "view" && game ? (
    <div className="-mt-[11%]">
      <img
        className="w-24 mx-auto mt-25"
        src="https://i.imgur.com/15968iJ.png"
        alt="logo"
      />

      <div className="w-[900px] h-[400px] mx-auto self-center bg-[#15141F]">
        <div
          className="top-0 right-0 flex justify-end p-2 "
          onClick={() => navigate("/duels")}
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19 5L5 19M19 19L5 5"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
            />
          </svg>
        </div>

        <div className="flex items-center justify-between w-full p-16 py-3 upper">
          <div
            className={merge(
              showValue &&
                game._winner !== "0" &&
                game._winner !== game.players[0]._id &&
                "opacity-25",
              "user1"
            )}
          >
            <div>
              <img
                alt={game.players[0].option === 0 ? "Heads" : "Tails"}
                src={
                  game.players[0].option === 0
                    ? "https://i.imgur.com/weRfAqS.png"
                    : "https://i.imgur.com/maaXi2c.png"
                }
                className="absolute w-10 h-10 rounded-full"
              />
              <img
                alt={truncate(game.players[0].username)}
                src={game.players[0].avatar}
                className={merge(
                  game._winner !== "0" &&
                    game._winner === game.players[0]._id &&
                    "border-lightblue border-4",
                  "w-24 h-24 rounded-full"
                )}
              />
            </div>
            <p className="mt-2 text-lg font-normal text-center text-white ">
              {game.players[0].username}
            </p>
          </div>
          <div className="flex-1 text-center">
            <p className="text-white ">
              {game.status === "PENDING"
                ? "Waiting For Player"
                : ["COMPLETED", "SHOW_GAME"].includes(game.status)
                ? "Results"
                : game.status === "ACTIVE"
                ? "this for rolling animation"
                : game.status === "EXPIRED" && "Game Expired"}
            </p>
          </div>
          <div
            className={merge(
              showValue &&
                game._winner !== "0" &&
                game._winner !== "0" &&
                game._winner !== game.players[1]._id &&
                "opacity-25",
              "user2"
            )}
          >
            {game.players[1] ? (
              <>
                <div>
                  <img
                    alt={game.players[1].option === 1 ? "Tails" : "Heads"}
                    src={
                      game.players[1].option === 1
                        ? "https://i.imgur.com/maaXi2c.png"
                        : "https://i.imgur.com/weRfAqS.png"
                    }
                    className="absolute w-10 h-10 rounded-full"
                  />
                  <img
                    alt={truncate(game.players[1].username)}
                    src={game.players[1].avatar}
                    className={merge(
                      game._winner !== "0" &&
                        game._winner === game.players[1]._id &&
                        "border-lightblue border-4",
                      "w-24 h-24 rounded-full"
                    )}
                  />
                </div>
                <p className="mt-2 text-lg font-normal text-center text-white w-40 truncate">
                  {(game.players[1].username)}
                </p>
              </>
            ) : (
              <Fragment>
                {game.status === "PENDING" &&
                  user &&
                  user._id === game.players[0]._id && (
                    <button
                      onClick={() => addBot()}
                      disabled={game.status !== "PENDING"}
                      className="px-4 py-2 text-white rounded-md bg-primarydark relative z-[99]"
                    >
                      Call Bot
                    </button>
                  )}

                {isAuthenticated && user._id !== game.players[0]._id && (
                  <button
                    onClick={() => joinGame()}
                    disabled={game.status !== "PENDING"}
                    className="px-4 py-2 text-white rounded-md bg-primarydark relative z-[99]"
                  >
                    Join Game
                  </button>
                )}
              </Fragment>
            )}
          </div>
        </div>

        <div className="flex justify-around w-full px-16 space-x-4 amounts">
          <div
            className={merge(
              showValue
                ? game._winner !== "0" &&
                  game._winner !== "0" &&
                  game._winner === game.players[0]._id
                  ? "text-[#75FF44]"
                  : "text-[#FF4949] opacity-25"
                : "text-white",
              "user1bet w-full bg-[#1E1D2B] text-center text-xl p-4 rounded-xl"
            )}
          >
            R$ {game.players[0].amountBet.toLocaleString()}
          </div>
          <div className="absolute inset-0 flex items-center justify-center z-[1]">
            <div className="flex flex-col items-center justify-center p-4 mt-12">
              <div className="mt-8"></div>

              <img
                className="w-24 rounded-full bg-[#15141F] mt-8"
                src={`https://adurite-images.onrender.com/images?assetId=${game.itemId}&width=150&height=150&format=Png`}
                alt={game.itemName}
              />
              <p className="mt-2 text-sm text-center text-white">
                {game.itemName}
              </p>

              <p className="mt-6 text-xs text-center text-white">
                {game.status === "EXPIRED"
                  ? `Expired @ ${moment(game.lastUpdated).format(
                      `@ DD/MM/YYYY HH:mm:ss`
                    )} `
                  : game.status === "PENDING"
                  ? `Created @ ${moment(game.createdAt).format(
                      `DD/MM/YYYY HH:mm:ss`
                    )} `
                  : ["COMPLETED", "SHOW_GAME"].includes(game.status)
                  ? `Flipped @ ${moment(game.lastUpdated).format(
                      `DD/MM/YYYY HH:mm:ss`
                    )}`
                  : game.status === "ACTIVE"
                  ? `Started Rolling @ ${moment(game.lastUpdated).format(
                      `DD/MM/YYYY HH:mm:ss`
                    )}`
                  : game.status === "CANCELLED" &&
                    `Cancelled @ ${moment(game.lastUpdated).format(
                      `DD/MM/YYYY HH:mm:ss`
                    )}`}
              </p>
              <p className="text-center bg-[#1E1D2A] w-fit p-2 px-4 rounded-lg text-white mt-2 text-sm">
                Provably Fair
              </p>
            </div>
          </div>
          <div
            className={merge(
              game.players[1] ? "visible" : "invisible",
              showValue
                ? game._winner !== "0" && game._winner === game.players[1]._id
                  ? "text-[#75FF44]"
                  : "text-[#FF4949] opacity-25"
                : "text-white",
              "user2bet w-full bg-[#1E1D2B] text-center text-xl p-4 rounded-xl"
            )}
          >
            R${" "}
            {game.players[1] && game.players[1]._id === game._winner
              ? game.reward.toLocaleString()
              : game.players[1] && game.players[1].amountBet.toLocaleString()}
          </div>
        </div>
      </div>
    </div>
  ) : type === "history" ? (
    <>
      <div className="w-[850px] h-[450px] flex flex-col mx-auto self-center -mt-[11%]">
        <img
          className="w-24 mx-auto mt-25"
          src="https://i.imgur.com/15968iJ.png"
          alt="logo"
        />

        <div
          className="w-full bg-primarylight rounded-[20px] text-white h-full"
          style={{
            boxShadow: "0px 4px 4px 0 rgba(0,0,0,0.25)",
          }}
        >
          <p className="p-8 text-xl font-bold">Last 20 Games</p>

          <ul className="w-full h-[355px] flex flex-col overflow-y-scroll p-8">
            {history?.map(({ players, itemF, imageUrl, price }, index) => {
              return (
                <div
                  key={index}
                  className="flex flex-col items-center justify-between w-full p-5 px-2 rounded-lg bg-primarylight md:p-0 md:flex-row"
                >
                  <div className="flex flex-row items-center space-x-5">
                    <img
                      src={
                        (players[0] && players[0].avatar) ?? "images/user.png"
                      }
                      className="w-16 h-16 rounded-full"
                      alt={players[0] && truncate(players[0].username)}
                      onClick={() => players[0] && navigate(`/profile?id=${players[0]._id}`, {
                        state: {
                          background: location
                        }
                      })}
                    />

                    <svg
                      width="20"
                      height="29"
                      viewBox="0 0 20 29"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.44034 3.81818L5.52841 10.9972H5.60795L7.69602 3.81818H10.8182L7.45739 14H3.67898L0.318182 3.81818H3.44034Z"
                        fill="white"
                      />
                      <path
                        d="M16.1449 19C16.1184 18.6686 15.9941 18.41 15.772 18.2244C15.5533 18.0388 15.2202 17.946 14.7727 17.946C14.4877 17.946 14.254 17.9808 14.0717 18.0504C13.8928 18.1167 13.7602 18.2079 13.674 18.3239C13.5878 18.4399 13.5431 18.5724 13.5398 18.7216C13.5331 18.8442 13.5547 18.9553 13.6044 19.0547C13.6574 19.1508 13.7403 19.2386 13.853 19.3182C13.9657 19.3944 14.1098 19.464 14.2855 19.527C14.4612 19.59 14.67 19.6463 14.9119 19.696L15.7472 19.875C16.3106 19.9943 16.7929 20.1518 17.1939 20.3473C17.5949 20.5429 17.9231 20.7732 18.1783 21.0384C18.4335 21.3002 18.6207 21.5952 18.7401 21.9233C18.8627 22.2514 18.9257 22.6094 18.929 22.9972C18.9257 23.6667 18.7583 24.2334 18.4268 24.6974C18.0954 25.1615 17.6214 25.5144 17.005 25.7564C16.3918 25.9983 15.6544 26.1193 14.7926 26.1193C13.9077 26.1193 13.1354 25.9884 12.4759 25.7266C11.8196 25.4647 11.3092 25.062 10.9446 24.5185C10.5833 23.9716 10.401 23.2723 10.3977 22.4205H13.0227C13.0393 22.732 13.1172 22.9938 13.2564 23.206C13.3956 23.4181 13.5911 23.5788 13.843 23.6882C14.0982 23.7976 14.4015 23.8523 14.7528 23.8523C15.0478 23.8523 15.2947 23.8158 15.4936 23.7429C15.6925 23.67 15.8433 23.5689 15.946 23.4396C16.0488 23.3104 16.1018 23.1629 16.1051 22.9972C16.1018 22.8414 16.0504 22.7055 15.951 22.5895C15.8549 22.4702 15.6958 22.3641 15.4737 22.2713C15.2517 22.1752 14.9517 22.0857 14.5739 22.0028L13.5597 21.7841C12.6581 21.5885 11.9472 21.2621 11.4268 20.8047C10.9098 20.344 10.6529 19.7159 10.6562 18.9205C10.6529 18.2741 10.8253 17.709 11.1733 17.2251C11.5246 16.7379 12.0102 16.3584 12.63 16.0866C13.2531 15.8149 13.9673 15.679 14.7727 15.679C15.5947 15.679 16.3056 15.8165 16.9055 16.0916C17.5054 16.3667 17.9678 16.7545 18.2926 17.255C18.6207 17.7521 18.7865 18.3338 18.7898 19H16.1449Z"
                        fill="white"
                      />
                    </svg>

                    <img
                      src={
                        (players[1] && players[1].avatar) ?? "images/user.png"
                      }
                      className="w-16 h-16 rounded-full"
                      alt={(players[1] && truncate(players[1].username)) ?? "Anita"}
                      onClick={() => players[1] && navigate(`/profile?id=${players[1]._id}`, {
                        state: {
                          background: location
                        }
                      })}
                    />
                  </div>

                  <div className="flex flex-row items-center mt-4 space-x-5 md:hidden md:space-x-0 md:mt-0 ">
                    <div className="px-1 my-4 rounded-lg">
                      <img src={imageUrl} className="mx-auto w-14" alt="Item" />
                    </div>
                    <div className="space-y-1">
                      <p className="flex items-center gap-1 text-xl font-bold text-white">
                        <img
                          src="images/currency.png"
                          alt="robux icon"
                          className="w-5 h-5"
                        />
                        {(
                          (itemF.value !== -1 && itemF.value) ??
                          itemF.rap
                        ).toLocaleString()}
                      </p>
                      <p className="text-md text-[#535165] text-center">
                        {scaleNum(
                          (itemF.value !== -1 && itemF.value) ?? itemF.rap
                        )}
                      </p>
                    </div>
                  </div>

                  <div className="hidden px-1 my-4 rounded-lg md:block">
                    <img src={imageUrl} className="mx-auto w-14" alt="Item" />
                  </div>

                  <div className="hidden space-y-1 md:block">
                    <p className="flex items-center gap-1 text-xl font-bold text-white">
                      <img
                        src="images/currency.png"
                        alt="robux icon"
                        className="w-5 h-5"
                      />
                      {(
                        (itemF.value !== -1 && itemF.value) || price * 2
                      ).toLocaleString()}
                    </p>
                    <p className="text-md text-[#535165] text-center">
                      {scaleNum(
                        (itemF.value !== -1 && itemF.value) || price * 2
                      )}
                    </p>
                  </div>
                </div>
              );
            })}
          </ul>
        </div>
      </div>
    </>
  ) : (
    <></>
  );
}

function Coinflip({ user, token, isAuthed: isAuthenticated, cf }: any) {
  const location = useLocation();
  const [games, setGames] = useState<any[]>([]);
  const [gamesTotal, setGamesTotal] = useState(0);
  const [sortedGames, setSortedGames] = useState<any[]>([]);

  const headsTextures = useMemo(
    () =>
      Array.from(
        { length: 209 },
        (_, i: number) =>
          `sprites/old_TennoTails/${
            i < 10 ? `0${i}` : i
          }_TennoTailsSpritesheet.png`
      ),
    []
  );

  const [game, setGame] = useState<any>(null);
  const [temp, setTemp] = useState<any>(null);
  const [showValue, setShowValue] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [id, setId] = useState(searchParams.get("id"));
  const [playAnim, setPlay] = useState<any>(false);
  const [startCountdown, setStart] = useState(false);

  const [joinDebounce, setJoinDebounce] = useState(false);

  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  const [maybe, setMaybe] = useLocalStorage("cf", []);

  const die = (array: any[], b: any) => {
    const index = array.findIndex((g) => g._id === b);
    if (index > -1) array.splice(index, 1);
  };

  const joinGame = async (i = id) => {
    if (joinDebounce) return;
    setJoinDebounce(true);

    const request = await fetch(`${API_URL}/coinflip/${i}/update?fetchAll=1`, {
      method: "POST",
      headers: {
        Authorization: token,
      },
    });

    if (request.status === 401)
      return toast.error((await request.json()).error);
    if (request.status === 500) return toast.error("Something went wrong");

    const newgame = await request.json();

    setId(newgame._id);

    setGames(
      newgame.games
        .reverse()
        .sort((a: any, b: any) => b.price - a.price)
        .map((game: any) => ({
          imageUrl: `https://adurite-images.onrender.com/images?assetId=${game.itemId}&width=150&height=150&format=Png`, //batchImages.find((i: any) => i.id === game.itemId).uri,
          ...game,
        }))
    );

    if (["COMPLETED", "SHOW_GAME"].includes(newgame.status)) {
      setGame({
        _id: newgame._id,
        players: newgame.players,
        itemId: newgame.itemId,
        itemName: newgame.itemName,
        price: newgame.price,
        reward: newgame.reward,
      });

      setPlay(true);
      setStart(false);
      setTemp({ ...newgame, imageUrl: newgame?.imageUrl ?? "" });
    } else setGame(newgame);

    setJoinDebounce(false);

    return toast.success("Joined Game!");
  };

  const addBot = async () => {
    if (joinDebounce) return;
    setJoinDebounce(true);

    const request = await fetch(`${API_URL}/coinflip/${id}/update`, {
      method: "POST",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ addBot: true }),
    });

    if (request.status === 401)
      return toast.error((await request.json()).error);
    if (request.status === 500) return toast.error("Something went wrong");

    // window.location.reload()

    setJoinDebounce(false);
    forceUpdate();

    return toast.success("Called bot!");
  };

  useEffect(
    () => {
      setGamesTotal(
        games.reduce((last, game: any) => last + (game.reward ?? game.price), 0)
      );

      if (games.length > 0)
        setSortedGames(games.sort((a, b) => b.price - a.price));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [games]
  );

  useEffect(() => setId(searchParams.get("id")), [location.search]);

  useEffect(() => {
    const fetchGame = async () => {
      setTemp(null);
      setGame(null);
      setPlay(false);
      setStart(false);
      setShowValue(false);

      const request = await fetch(`${API_URL}/coinflip/game/${id}?fetchAll=1`);

      if (request.status !== 200) return;

      const response = await request.json();

      setGames(
        response.games
          .reverse()
          .sort((a: any, b: any) => b.price - a.price)
          .map((game: any) => ({
            imageUrl: `https://adurite-images.onrender.com/images?assetId=${game.itemId}&width=150&height=150&format=Png`, //batchImages.find((i: any) => i.id === game.itemId).uri,
            ...game,
          }))
      );

      if (["COMPLETED", "SHOW_GAME"].includes(response.status)) {
        setGame({
          _id: response._id,
          players: response.players,
          itemId: response.itemId,
          itemName: response.itemName,
          price: response.price,
          reward: response.reward,
        });

        setPlay(true);
        setStart(false);
        setTemp({ ...response, imageUrl: response?.imageUrl ?? "" });
      } else setGame(response);

      forceUpdate();
    };

    const fetchGames = async () => {
      const request = await fetch(`${API_URL}/coinflip/games`);

      if (request.status !== 200) return;

      const response = await request.json();

      if (!("games" in response)) return;

      if (response.games.length > 0) {
        const limIds = response.games.map((i: any) => i.itemId);

        // const batchImages = await getBatchImages(limIds);

        setGames(
          response.games
            .reverse()
            .sort((a: any, b: any) => b.price - a.price)
            .map((game: any) => ({
              imageUrl: `https://adurite-images.onrender.com/images?assetId=${game.itemId}&width=150&height=150&format=Png`, //batchImages.find((i: any) => i.id === game.itemId).uri,
              ...game,
            }))
        );
      }
    };

    if (id) fetchGame();
    if (!id) fetchGames();
  }, [id]);

  useEffect(() => {
    const gameRemoved = (gameId: any) => {
      console.log("removed game");

      const temp = [...sortedGames];
      die(temp, gameId);

      setGames(temp.sort((a, b) => b.price - a.price));
      setSortedGames(temp.sort((a, b) => b.price - a.price));
      setMaybe((prev: string[]) => prev.filter((b: string) => b !== gameId));
    };

    const gameCreated = async (game: any) => {
      // const imageUrl = await getItemImage(game.itemId);
      setGames((g: any) =>
        [
          ...g,
          {
            imageUrl: `https://adurite-images.onrender.com/images?assetId=${game.itemId}&width=150&height=150&format=Png`,
            ...game,
          },
        ].sort((a: any, b: any) => b.price - a.price)
      );
    };

    const gameUpdated = (updGame: any) => {
      setGames((games) =>
        games
          .sort((a: any, b: any) => b.price - a.price)
          .map((gam2: any) =>
            gam2._id === updGame._id
              ? { imageUrl: gam2.imageUrl, ...updGame }
              : gam2
          )
      );

      // console.log('updated', updGame);

      if (updGame._id === id) {
        setGame((previousGame: any) => ({
          imageUrl: previousGame?.imageUrl ?? "",
          ...updGame,
        }));
      }
    };

    const gameFinished = (updGame: any) => {
      setGames((games) =>
        games
          .sort((a: any, b: any) => b.price - a.price)
          .map((gam2: any) =>
            gam2._id === updGame._id
              ? { imageUrl: gam2.imageUrl, ...updGame }
              : gam2
          )
      );

      // console.log('finished', updGame);

      if (updGame._id === id) {
        // setTimeLeft(5);
        setStart(true);
        setGame((prev: any) => ({
          imageUrl: prev?.imageUrl ?? "",
          ...prev,
          players: updGame.players,
        }));

        setTimeout(() => {
          setPlay(true);
          setStart(false);
          setTemp({ ...updGame, imageUrl: game?.imageUrl ?? "" });
        }, 5000);
      }
    };

    const ftrt = (id: string) => setMaybe((prev: any) => [...prev, id]);

    drops.on("-test", ftrt);
    drops.on("new-cf", gameCreated);
    drops.on("remove-cf", gameRemoved);
    drops.on("updated-cf", gameUpdated);
    drops.on("finished-cf", gameFinished);

    return () => {
      drops.off("-test", ftrt);
      drops.off("new-cf", gameCreated);
      drops.off("remove-cf", gameRemoved);
      drops.off("updated-cf", gameUpdated);
      drops.off("finished-cf", gameFinished);
    };
  }, [user, id]);

  return (
    <div>
      <div
        className={merge(
          "grid items-center grid-cols-3 gap-4 mt-5 md:grid-cols-4"
        )}
      >
        <div className="w-full p-3 rounded-lg bg-primarylight">
          <p className="flex items-center text-white text-md sm:text-xl sm:relative">
            <img
              src="/images/currency.png"
              alt="Robux"
              className="inline-block w-4 h-4 mr-1 -mt-1"
            />
            {gamesTotal.toLocaleString()}
          </p>
          <p className="text-[#C3C3C3]">Total Value</p>
        </div>
        <div className="w-full p-3 rounded-lg bg-primarylight">
          <p className="flex items-center text-white text-md sm:text-xl sm:relative">
            {scaleNum(gamesTotal).toLocaleString()}
          </p>
          <p className="text-[#C3C3C3]">Total USD</p>
        </div>
        <div className="w-full p-3 rounded-lg bg-primarylight">
          <p className="text-white text-md sm:text-xl">
            {games.length.toLocaleString()}
          </p>
          <p className="text-[#C3C3C3]">Total Duels</p>
        </div>
        <div className="flex flex-row items-center justify-center w-full h-full col-span-3 p-2 mx-auto space-x-5 rounded-lg md:col-auto bg-primarylight">
          {/* <Link
            className="p-4 mx-auto font-bold text-center text-white bg-blue-500 rounded-lg"
            state={{ background: location, type: "create" }}
            to="/duels"
        >
            Create
        </Link> */}
          <Link
            className="bg-[#448FFF] h-fit text-white p-3 px-6 rounded-full"
            state={{ background: location, type: "create" }}
            to="/duels"
          >
            Create
          </Link>
          <Link
            className="bg-[#262535] h-fit text-white p-3 px-6 rounded-full"
            state={{ background: location, type: "history" }}
            to="/duels"
          >
            History
          </Link>
        </div>

        <div className="flex w-full" />
      </div>

      <div
        className={merge(
          "grid grid-cols-1 gap-4 mt-5 justify-items-center justify-content-center"
        )}
      >
        <AnimatePresence mode="popLayout">
          {sortedGames.map((game) => (
            <Fragment key={game._id}>
              <DuelCard
                game={game}
                user={user}
                location={location}
                joinGame={joinGame}
                cf={cf}
              />
            </Fragment>
          ))}
        </AnimatePresence>
      </div>

      <AnimatePresence>
        {
          game && (
            <Fragment>
              <Helmet>
                <title>5050</title>
                <meta
                  name="description"
                  content={`${truncate(game.players[0].username)}'s Coinflip`}
                />
              </Helmet>

              <div
                className="fixed top-0 left-0 w-full h-full backdrop-blur-sm z-[21]"
                onClick={() => {
                  setId("");
                  setTemp(null);
                  setGame(null);
                  setPlay(false);
                  setStart(false);
                  setShowValue(false);
                  setSearchParams(undefined);
                }}
              ></div>

              <div className="fixed w-full lg:w-[900px] top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-[100] p-3">
                <img
                  className="w-24 mx-auto mt-25 "
                  src="https://i.imgur.com/15968iJ.png"
                  alt="logo"
                />

                <div className="w-full h-[400px] mx-auto self-center bg-[#15141F]">
                  <button
                    className="relative top-0 right-0 z-50 flex justify-end p-2 ml-auto"
                    onClick={() => {
                      setId("");
                      setTemp(null);
                      setGame(null);
                      setPlay(false);
                      setStart(false);
                      setShowValue(false);
                      setSearchParams(undefined);
                    }}
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M19 5L5 19M19 19L5 5"
                        stroke="white"
                        strokeWidth="2"
                        strokeLinecap="round"
                      />
                    </svg>
                  </button>

                  <div className="w-full">
                    <div className="flex items-center justify-between w-full p-16 py-3 upper ">
                      <div
                        className={merge(
                          showValue &&
                            game._winner !== "0" &&
                            game._winner !== game.players[0]._id &&
                            "opacity-25",
                          "user1"
                        )}
                      >
                        <div className="">
                          <img
                            alt={
                              game.players[0].option === 0 ? "Heads" : "Tails"
                            }
                            src={
                              game.players[0].option === 0
                                ? "https://i.imgur.com/weRfAqS.png"
                                : "https://i.imgur.com/maaXi2c.png"
                            }
                            className="absolute w-10 h-10 rounded-full"
                          />
                          <img
                            alt={truncate(game.players[0].username)}
                            src={game.players[0].avatar}
                            className={merge(
                              game._winner !== "0" &&
                                game._winner === game.players[0]._id &&
                                showValue &&
                                "border-lightblue border-4",
                              "w-24 h-24 rounded-full mx-6"
                            )}
                          />
                        </div>

                        <p className="mt-2 text-lg font-normal text-center text-white w-[150px] truncate">
                          {game.players[0].username} 
                        </p>
                      </div>
                      <div className="flex items-center flex-1 text-center">
                        {startCountdown && (
                          <div
                            className={merge(
                              "w-24 h-24 text-purple",
                              startCountdown ? "flex flex-col" : "hidden"
                            )}
                          >
                            <span
                              className={merge(
                                "relative font-black text-center ml-[226px] top-[30px] -mt-2",
                                startCountdown &&
                                  "before:animate-[count_5s_linear_forwards] before:content-[5]"
                              )}
                            />

                            <svg
                              className="absolute ml-12 mt-2 -top-[25px]"
                              style={{
                                transform: "rotateY(-180deg) rotateZ(-90deg)",
                              }}
                            >
                              <circle
                                r="36"
                                cx="40"
                                cy="40"
                                className={merge(
                                  "stroke-[2px] stroke-[white] fill-none ",
                                  startCountdown &&
                                    "animate-[countdown_5s_linear_forwards]"
                                )}
                                style={{
                                  strokeDasharray: "226px",
                                  strokeDashoffset: "0px",
                                  strokeWidth: "2px",
                                }}
                              />
                            </svg>
                          </div>
                        )}

                        <Stage
                          width={100}
                          height={100}
                          className={merge(
                            (showValue && "hidden") || (!playAnim && "hidden"),
                            "w-34 h-34 scale-[1.25] mx-auto self-center"
                          )}
                          options={{ backgroundColor: 0x15141f }}
                        >
                          <Container width={200} height={150}>
                            <AnimatedSprite
                              loop={false}
                              onComplete={() => {
                                setTimeout(() => {
                                  setPlay(false);
                                  setGame((prev: any) => ({
                                    ...temp,
                                    imageUrl: game?.imageUrl ?? "",
                                  }));
                                  setShowValue(true);
                                }, 1000);
                              }}
                              anchor={0}
                              scale={0.11}
                              isPlaying={playAnim}
                              images={headsTextures}
                            />
                          </Container>
                        </Stage>
                      </div>
                      <div
                        className={merge(
                          showValue &&
                            game._winner !== "0" &&
                            game._winner !== "0" &&
                            game._winner !== game.players[1]._id &&
                            "opacity-25",
                          "user2"
                        )}
                      >
                        {game.players[1] ? (
                          <>
                            <div>
                              <img
                                alt={
                                  game.players[1].option === 1
                                    ? "Tails"
                                    : "Heads"
                                }
                                src={
                                  game.players[1].option === 1
                                    ? "https://i.imgur.com/maaXi2c.png"
                                    : "https://i.imgur.com/weRfAqS.png"
                                }
                                className="absolute w-10 h-10 rounded-full"
                              />
                              <img
                                alt={truncate(game.players[1].username)}
                                src={game.players[1].avatar}
                                className={merge(
                                  game._winner !== "0" &&
                                    game._winner === game.players[1]._id &&
                                    showValue &&
                                    "border-lightblue border-4",
                                  "w-24 h-24 rounded-full mx-6"
                                )}
                              />
                            </div>
                            <p className="mt-2 text-lg font-normal text-center text-white w-[150px] truncate">
                              {(game.players[1].username)}
                            </p>
                          </>
                        ) : (
                          <Fragment>
                            {game.status === "PENDING" &&
                              user &&
                              user._id === game.players[0]._id && (
                                <button
                                  onClick={() => addBot()}
                                  disabled={game.status !== "PENDING"}
                                  className="px-4 py-2 text-white rounded-md bg-primarydark relative z-[99]"
                                >
                                  Call Bot
                                </button>
                              )}

                            {isAuthenticated &&
                              user._id !== game.players[0]._id && (
                                <button
                                  onClick={() => joinGame()}
                                  disabled={game.status !== "PENDING"}
                                  className="px-4 py-2 text-white rounded-md bg-primarydark relative z-[99]"
                                >
                                  Join Game
                                </button>
                              )}
                          </Fragment>
                        )}
                      </div>
                    </div>

                    <div className="flex justify-around w-full space-x-4 amounts ">
                      <div
                        className={merge(
                          showValue
                            ? game._winner !== "0" &&
                              game._winner !== "0" &&
                              game._winner === game.players[0]._id
                              ? "text-[#75FF44]"
                              : "text-[#FF4949] opacity-25"
                            : "text-white",
                          "user1bet w-full bg-[#1E1D2B] text-center text-xl p-4 rounded-xl"
                        )}
                      >
                        R$ {game.players[0].amountBet.toLocaleString()}
                      </div>
                      <div className="absolute inset-0 flex items-center justify-center z-[1]">
                        <div className="flex flex-col items-center justify-center p-4 mt-24">
                          <div className="mt-20"></div>

                          <img
                            className="w-24 rounded-full bg-[#15141F] mt-8"
                            src={`https://adurite-images.onrender.com/images?assetId=${game.itemId}&width=150&height=150&format=Png`}
                            alt={game.itemName}
                          />
                          <p className="mt-2 text-sm text-center text-white">
                            {game.itemName}
                          </p>

                          <p className="mt-6 text-xs text-center text-white">
                            {game.status === "EXPIRED"
                              ? `Expired @ ${moment(game.lastUpdated).format(
                                  `DD/MM/YYYY HH:mm:ss`
                                )} `
                              : game.status === "PENDING"
                              ? `Created @ ${moment(game.createdAt).format(
                                  `DD/MM/YYYY HH:mm:ss`
                                )} `
                              : ["COMPLETED", "SHOW_GAME"].includes(
                                  game.status
                                ) && showValue
                              ? `Flipped @ ${moment(game.lastUpdated).format(
                                  `DD/MM/YYYY HH:mm:ss`
                                )}`
                              : game.status === "ACTIVE"
                              ? `Started Rolling @ ${moment(
                                  game.lastUpdated
                                ).format(`DD/MM/YYYY HH:mm:ss`)}`
                              : game.status === "CANCELLED" &&
                                `Cancelled @ ${moment(game.lastUpdated).format(
                                  `DD/MM/YYYY HH:mm:ss`
                                )}`}
                          </p>
                          <p className="text-center bg-[#1E1D2A] w-fit p-2 px-4 rounded-lg text-white mt-2 text-sm">
                            Provably Fair
                          </p>
                        </div>
                      </div>
                      <div
                        className={merge(
                          game.players[1] ? "visible" : "invisible",
                          showValue
                            ? game._winner !== "0" &&
                              game._winner === game.players[1]._id
                              ? "text-[#75FF44]"
                              : "text-[#FF4949] opacity-25"
                            : "text-white",
                          "user2bet w-full bg-[#1E1D2B] text-center text-xl p-4 rounded-xl"
                        )}
                      >
                        R${" "}
                        {game.players[1] && game.players[1]._id === game._winner
                          ? game.reward.toLocaleString()
                          : game.players[1] &&
                            game.players[1].amountBet.toLocaleString()}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Fragment>
          )
          // ) : <Navigate to='/duels' />
        }

        {!game && (
          <Helmet>
            <title>5050</title>
            <meta name="description" content={`Play PVP Games`} />
          </Helmet>
        )}
      </AnimatePresence>
    </div>
  );
}

export default connect((state: any) => ({
  user:
    state.auth.isAuthenticated && !state.auth.isLoading
      ? state.auth.user
      : null,
  isAuthed: state.auth.isAuthenticated,
  isLoading: state.auth.isLoading,
  token: state.auth.token,
}))(Coinflip);
