import { store } from "./redux/store";
import { GetPublicSettings, drops, withAuth } from "./utils/api";

import toast, { Toaster } from "react-hot-toast";
import { Provider, connect } from "react-redux";
import { AnimatePresence } from "framer-motion";
import { useState, useEffect, Fragment, createContext } from "react";
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";

import Loading from "./components/views/Loading";
import Private from "./components/views/Private";
import Testing from "./components/views/Testing";
import Jackpot from "./components/views/Jackpot";
import NotFound from "./components/views/NotFound";
import Coinflip from "./components/views/Coinflip";
import Maintenence from "./components/views/Maintenance";
import DiscordAuth from "./components/views/DiscordAuth";

import ModalRoutes from "./components/views/modals/ModalRoutes";

import Navbar from "./components/Navbar/Navbar";
// import Sidebar from "./components/Sidebar/Sidebar";
import LiveChat from "./components/LiveChat/LiveChat";
import BottomNavbar from "./components/BottomNavbar/BottomNavbar";
import { logout, setToken, updateUser } from "./redux/actions/auth";
import Wallet from "./components/views/Wallet";
import { toggleChat } from "./redux/actions/state";
import { merge } from "./utils/tw";
import Marketplace from "./components/views/Marketplace";

interface SiteSettings {
  id: string;
  visibilty: string;
  coinflipEnabled: boolean;
  coinflipTax: number;
  caseBattlesEnabled: boolean;
  caseBattlesTax: number;
  chatEnabled: boolean;
}

interface ReduxChatProps {
  isChatOpen: boolean;
  isAuthenticated: boolean;
  isLoading: boolean;
  user: any;
  token: string;
}

const ConnectedChat = connect((state: any) => ({
  isChatOpen: state.state.isChatOpen,
  isAuthenticated: state.auth.isAuthenticated,
  isLoading: state.auth.isLoading,
  user: state.auth.user,
  token: state.auth.token,
}))(
  ({ isChatOpen, isAuthenticated, isLoading, user, token }: ReduxChatProps) => {
    return (
      <AnimatePresence>
        {isChatOpen && (
          <LiveChat
            user={isAuthenticated && !isLoading ? user : null}
            token={token}
          />
        )}
      </AnimatePresence>
    );
  }
);

const ConnectedDiscordAuth = connect(null, { setToken })(({ setToken }) => (
  <>
    <DiscordAuth setToken={setToken} />
  </>
));

const DepositWalletAction = connect((state: any) => ({
  user:
    state.auth.isAuthenticated && !state.auth.isLoading
      ? state.auth.user
      : null,
  isAuthenticated: state.auth.isAuthenticated,
  isLoading: state.auth.isLoading,
  token: state.auth.token,
}))(
  (props: {
    user: any;
    isAuthenticated: boolean;
    isLoading: boolean;
    token: string;
  }) => (
    <>
      <Wallet {...props} type="deposit" />
    </>
  )
);

const WithdrawWalletAction = connect((state: any) => ({
  user:
    state.auth.isAuthenticated && !state.auth.isLoading
      ? state.auth.user
      : null,
  isAuthenticated: state.auth.isAuthenticated,
  isLoading: state.auth.isLoading,
  token: state.auth.token,
}))(
  (props: {
    user: any;
    isAuthenticated: boolean;
    isLoading: boolean;
    token: string;
  }) => (
    <>
      <Wallet {...props} type="withdraw" />
    </>
  )
);

const MarkeplaceC = connect((state: any) => ({
  user:
    state.auth.isAuthenticated && !state.auth.isLoading
      ? state.auth.user
      : null,
  isAuthenticated: state.auth.isAuthenticated,
  isLoading: state.auth.isLoading,
  token: state.auth.token,
}))(
  (props: {
    user: any;
    isAuthenticated: boolean;
    isLoading: boolean;
    token: string;
  }) => (
    <>
      <Marketplace {...props} />
    </>
  )
);

const Funny = connect(
  (state: any) => ({
    token: state.auth.token,
    user:
      state.auth.isAuthenticated && !state.auth.isLoading
        ? state.auth.user
        : null,
  }),
  { updateUser, logout, setToken }
)(({ token, updateUser, user, setToken }: any) => {
  useEffect(() => {
    const interval = setInterval(() => {
      if (token) updateUser(token);
    }, 10000);

    const interval1 = setInterval(() => {
      if (token) withAuth(token, logout);
    }, 5000);

    const only_cuz = () => {
      if (token) updateUser(token);
      if (token) withAuth(token, logout);
    };

    window.addEventListener("beforeunload", only_cuz);

    if (token) setToken({ token });

    return () => {
      clearInterval(interval);
      clearInterval(interval1);

      window.removeEventListener("beforeunload", only_cuz);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const notifications = (payload: {
      type: string;
      id: string;
      amount: number;

      from?: string;
    }) => {
      // if (payload.type === 'game-won' && !recievedMsgs["game-won"].includes(payload.id)) {
      //     recievedMsgs["game-won"].push(payload.id)

      //     return toast.success(`You won ${payload.amount.toLocaleString()}`, {
      //         icon: '😜',
      //         position: 'top-right',
      //     })
      // }

      // if (payload.type === 'game-lost' && !recievedMsgs["game-lost"].includes(payload.id)) {
      //     recievedMsgs["game-lost"].push(payload.id)

      //     return toast.error(`You lost ${payload.amount.toLocaleString()}`, {
      //         icon: '😭',
      //         position: 'top-right',
      //     })
      // }

      if (payload.type === "new-tip") {
        return toast.success(`Recieved tip from ${payload.from}`, {
          icon: "💰",
          position: "top-right",
        });
      }
    };

    if (user && user._id !== null) drops.on(user._id, notifications);

    return () => {
      if (user && user._id !== null) drops.off(user._id, notifications);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
});

const ToggleChatButton = connect((state: any) => ({ isOpen: state.state.isChatOpen }), { toggleChat })(
  ({ toggleChat , isOpen }: any) => (
    <div 
      onClick={() => toggleChat()} 
      className={merge(
        !isOpen && "ml-0", 
        isOpen && 
        "ml-[290px]", "hidden 2xl:absolute w-16 h-16 rounded-r-lg bg-[#232233] 2xl:flex items-center justify-center bottom-0 mb-[50px] z-[20]"
      )}
    >
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19.4666 9.86644V5.06644C19.4666 3.88823 18.5114 2.93311 17.3332 2.93311H4.53324C3.35503 2.93311 2.3999 3.88823 2.3999 5.06644V13.4143C2.3999 14.5925 3.35503 15.5476 4.53324 15.5476H6.2028V19.9998L10.655 15.5476H10.9332M16.4405 18.2838L19.2231 21.0664V18.2838H19.4666C20.6448 18.2838 21.5999 17.3287 21.5999 16.1505V12.5331C21.5999 11.3549 20.6448 10.3998 19.4666 10.3998H13.0666C11.8884 10.3998 10.9332 11.3549 10.9332 12.5331V16.1505C10.9332 17.3287 11.8884 18.2838 13.0666 18.2838H16.4405Z"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  )
)

function ReactRoutes({ cf }: any) {
  const location = useLocation();
  const previousLocation = location.state?.background;

  return (
    <Fragment>
      <Routes location={previousLocation || location}>
        <Route path="/" element={<Navigate to="/duels" />} />

        <Route path="/duels" element={<Coinflip cf={cf} />} />
        <Route path="/jackpot" Component={Jackpot} />

        <Route path="/deposit" Component={DepositWalletAction} />
        <Route path="/withdraw" Component={WithdrawWalletAction} />
        <Route path="/marketplace" Component={MarkeplaceC} />

        <Route
          path="*"
          element={
            <Navigate to="/" />
            // <div className="overflow-hidden">
            //   <NotFound text="Not Found" />
            // </div>
          }
        />

        <Route path="/discord" Component={ConnectedDiscordAuth as any} />

        <Route
          path="/profile"
          Component={(props: any) => {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            const navigate = useNavigate();
            // eslint-disable-next-line react-hooks/rules-of-hooks
            const location = useLocation();
            // eslint-disable-next-line react-hooks/rules-of-hooks, @typescript-eslint/no-unused-vars
            const [searchParams, setSearchParams] = useSearchParams();

            // eslint-disable-next-line react-hooks/rules-of-hooks
            useEffect(() => {
              if (searchParams.get("id") && !previousLocation)
                navigate(`/profile?id=${searchParams.get("id")}`, {
                  state: {
                    background: location,
                  },
                });
              if (!searchParams.get("id"))
                navigate("/duels", { replace: true });
            // eslint-disable-next-line react-hooks/exhaustive-deps
            }, [searchParams]);

            return <></>;
          }}
        />

        <Route
          path="/logout"
          Component={connect(null, { logout })(
            ({ logout }: { logout: () => void }) => {
              // eslint-disable-next-line react-hooks/rules-of-hooks
              const nav = useNavigate();

              // eslint-disable-next-line react-hooks/rules-of-hooks
              useEffect(() => {
                logout();

                nav("/");
                // eslint-disable-next-line react-hooks/exhaustive-deps
              }, []);

              return <></>;
            }
          )}
        />
      </Routes>

      {previousLocation && (
        <Routes>
          <Route
            path="/:modal"
            element={
              <AnimatePresence mode="wait">
                <ModalRoutes location={previousLocation} />
              </AnimatePresence>
            }
          />
        </Routes>
      )}
    </Fragment>
  );
}

export function useLocalStorage(key: string, initialValue?: any) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key);
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      // If error also return initialValue
      console.log(error);
      return initialValue;
    }
  });

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value: any) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Save to local storage
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.log(error);
    }
  };

  return [storedValue, setValue];
}

function RegularLayout() {
  const cf = useLocalStorage("cf", []);
  const CFContext = createContext([]);

  return (
    <Provider store={store}>
      <Toaster containerStyle={{zIndex: 9999999}} />

      <Funny />

      <div className="h-screen overflow-y-hidden font-medium bg-primary">
        <BrowserRouter>
          <Navbar />

          <div
            className={`flex flex-row flex-1`}
            style={{ maxHeight: "calc(100vh - 13vh)" }}
          >
            <ToggleChatButton />
            <ConnectedChat />

            <main className="container flex-1 p-2 mx-auto overflow-y-auto bg-[#0E0F18]">
              <CFContext.Provider value={cf[0]}>
                <ReactRoutes cf={cf} />
              </CFContext.Provider>
            </main>
          </div>
        </BrowserRouter>
        <BottomNavbar />
      </div>
    </Provider>
  );
}

function App() {
  const [loading, setLoading] = useState(true);
  const [siteSettings, setSiteSettings] = useState<null | SiteSettings>(null);

  useEffect(() => {
    document.title = "50/50";
  }, []);

  useEffect(() => {
    const firstRequest = async () => {
      setLoading(true);

      const settings = await GetPublicSettings();

      if (!settings) return setSiteSettings(null);

      setSiteSettings(settings);

      setLoading(false);
    };

    firstRequest();
  }, []);

  const mainLayout = (siteSettings &&
    (siteSettings.visibilty === "public" ? (
      <RegularLayout />
    ) : siteSettings.visibilty === "private" ? (
      <Private />
    ) : siteSettings.visibilty === "testing" ? (
      <Testing>
        <RegularLayout />
      </Testing>
    ) : siteSettings.visibilty === "maintenance" ? (
      <Maintenence />
    ) : (
      <NotFound text="Not Found" />
    ))) ?? <NotFound text="Not Avaliable" />;

  return loading ? <Loading /> : mainLayout;
}

export default App;
