// src/components/DuelCard/DuelCard.js

import { Fragment, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { motion } from "framer-motion";
import { scaleNum, truncate } from "../../utils/string";
import { merge } from "../../utils/tw";
import { useLocalStorage } from "../../App";

interface DuelCardProps {
  game: {
    itemName: string;
    itemF: any;
    imageUrl: string;
    gameType: string;
    players: any[];
    price: number;
    status: "ACTIVE" | "PENDING" | "COMPLETED" | "SHOW_GAME";
    _id: string;
    _winner: string;
  };
  location: any;
  user: any;
  joinGame: any;
  cf: any[];
}

function DuelCard({
  game: {
    itemName,
    itemF,
    imageUrl,
    gameType,
    players,
    price,
    status: _status,
    _id,
    _winner: winner,
  },
  cf,
  user,
  location,
  joinGame,
}: DuelCardProps) {
  const [_winner, setWinner] = useState("0");
  const [useless, setUseless] = useState(false);
  const [status, setStatus] = useState("PENDING");
  const [startCountdown, setStart] = useState(false);
  const [maybe, setMaybe] = useLocalStorage("cf", []);

  const navigate = useNavigate();

  useEffect(() => {
    if (["SHOW_GAME"].includes(_status) && !maybe.includes(_id)) {
      setStart(true);
      setUseless(true);

      setTimeout(() => {
        setWinner(winner);
        setStatus(_status);
        setStart(false);
      }, 5000);
    } else if (["SHOW_GAME"].includes(_status) && maybe.includes(_id)) {
      setWinner(winner);
      setStatus(_status);
    } else setStatus(_status);
  }, [_status])
  
  return (
    players && (
      <motion.div
        key={_id}
        layout
        className="w-full px-2 rounded-lg bg-primarylight"
        animate="visible"
        // initial="hidden"
        exit="hidden"
        variants={{
          hidden: {
            opacity: 0,
            scale: 0.8
          },
          visible: {
            opacity: 1,
            scale: 1
          },
        }}
        transition={{
          duration: 0.5,
          type: "spring"
        }}
      >
        <div className="flex flex-col items-center justify-between p-5 md:p-0 md:flex-row">
          <div className="flex flex-row items-center space-x-5">
            <div className="flex">
              <img
                alt={players[0].option === 0 ? "Heads" : "Tails"}
                src={
                  players[0].option === 0
                    ? "https://i.imgur.com/weRfAqS.png"
                    : "https://i.imgur.com/maaXi2c.png"
                }
                className="relative w-10 h-10 -mt-1 -mr-5 rounded-full"
              />
              <img
                src={(players[0] && players[0].avatar) ?? "images/user.png"}
                className={merge(
                  _winner !== "0" &&
                    _winner === players[0]._id &&
                    "border-lightblue border-4",
                  "w-16 h-16 rounded-full"
                )}
                alt={players[0] && truncate(players[0].username)}
                onClick={() => navigate(`/profile?id=${players[0]._id}`, {
                  state: {
                    background: location
                  }
                })}
              />
            </div>

            <svg
              width="20"
              height="29"
              viewBox="0 0 20 29"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.44034 3.81818L5.52841 10.9972H5.60795L7.69602 3.81818H10.8182L7.45739 14H3.67898L0.318182 3.81818H3.44034Z"
                fill="white"
              />
              <path
                d="M16.1449 19C16.1184 18.6686 15.9941 18.41 15.772 18.2244C15.5533 18.0388 15.2202 17.946 14.7727 17.946C14.4877 17.946 14.254 17.9808 14.0717 18.0504C13.8928 18.1167 13.7602 18.2079 13.674 18.3239C13.5878 18.4399 13.5431 18.5724 13.5398 18.7216C13.5331 18.8442 13.5547 18.9553 13.6044 19.0547C13.6574 19.1508 13.7403 19.2386 13.853 19.3182C13.9657 19.3944 14.1098 19.464 14.2855 19.527C14.4612 19.59 14.67 19.6463 14.9119 19.696L15.7472 19.875C16.3106 19.9943 16.7929 20.1518 17.1939 20.3473C17.5949 20.5429 17.9231 20.7732 18.1783 21.0384C18.4335 21.3002 18.6207 21.5952 18.7401 21.9233C18.8627 22.2514 18.9257 22.6094 18.929 22.9972C18.9257 23.6667 18.7583 24.2334 18.4268 24.6974C18.0954 25.1615 17.6214 25.5144 17.005 25.7564C16.3918 25.9983 15.6544 26.1193 14.7926 26.1193C13.9077 26.1193 13.1354 25.9884 12.4759 25.7266C11.8196 25.4647 11.3092 25.062 10.9446 24.5185C10.5833 23.9716 10.401 23.2723 10.3977 22.4205H13.0227C13.0393 22.732 13.1172 22.9938 13.2564 23.206C13.3956 23.4181 13.5911 23.5788 13.843 23.6882C14.0982 23.7976 14.4015 23.8523 14.7528 23.8523C15.0478 23.8523 15.2947 23.8158 15.4936 23.7429C15.6925 23.67 15.8433 23.5689 15.946 23.4396C16.0488 23.3104 16.1018 23.1629 16.1051 22.9972C16.1018 22.8414 16.0504 22.7055 15.951 22.5895C15.8549 22.4702 15.6958 22.3641 15.4737 22.2713C15.2517 22.1752 14.9517 22.0857 14.5739 22.0028L13.5597 21.7841C12.6581 21.5885 11.9472 21.2621 11.4268 20.8047C10.9098 20.344 10.6529 19.7159 10.6562 18.9205C10.6529 18.2741 10.8253 17.709 11.1733 17.2251C11.5246 16.7379 12.0102 16.3584 12.63 16.0866C13.2531 15.8149 13.9673 15.679 14.7727 15.679C15.5947 15.679 16.3056 15.8165 16.9055 16.0916C17.5054 16.3667 17.9678 16.7545 18.2926 17.255C18.6207 17.7521 18.7865 18.3338 18.7898 19H16.1449Z"
                fill="white"
              />
            </svg>

            <div className="flex">
              {players[1] && (
                <img
                  alt={players[1].option === 0 ? "Heads" : "Tails"}
                  src={
                    players[1].option === 0
                      ? "https://i.imgur.com/weRfAqS.png"
                      : "https://i.imgur.com/maaXi2c.png"
                  }
                  className="relative w-10 h-10 -mt-1 -mr-5 rounded-full"
                />
              )}
              <img
                src={(players[1] && players[1].avatar) ?? "images/user.png"}
                className={merge(
                  (_winner !== "0" && ("_id" in players[1])) &&
                    _winner === players[1]._id &&
                    "border-lightblue border-4",
                  "w-16 h-16 rounded-full"
                )}
                alt={(players[1] && truncate(players[1].username)) ?? "Anita"}
                onClick={() => players[1] && navigate(`/profile?id=${players[1]._id}`, {
                  state: {
                    background: location
                  }
                })}
              />
            </div>
          </div>

          <div className="flex flex-col items-center mt-4 md:hidden md:flex-row md:mt-0 ">
            <div className="px-1 my-4 rounded-lg">
              <img src={imageUrl} className="mx-auto w-14" alt="Item" />
            </div>
            <div className="space-y-1">
              <p className="flex items-center gap-1 text-xl font-bold text-white">
                <img
                  src="images/currency.png"
                  alt="robux icon"
                  className="w-5 h-5"
                />
                {(
                  (itemF.value !== -1 && itemF.value) ||
                  price * 2
                ).toLocaleString()}
              </p>
              <p className="text-md text-[#535165] text-center">
                {scaleNum((itemF.value !== -1 && itemF.value) || price * 2)}
              </p>
            </div>
          </div>

          <div className="hidden px-1 my-4 rounded-lg md:block">
            <img src={imageUrl} className="mx-auto w-14" alt="Item" />
          </div>

          <div className="hidden space-y-1 md:block">
            <p className="flex items-center gap-1 text-xl font-bold text-white">
              <img
                src="images/currency.png"
                alt="robux icon"
                className="w-5 h-5"
              />
              {(
                (itemF.value !== -1 && itemF.value) ||
                price * 2
              ).toLocaleString()}
            </p>
            <p className="text-md text-[#535165] text-center">
              {scaleNum((itemF.value !== -1 && itemF.value) || price * 2)}
            </p>
          </div>

          <div
            className={merge(
              // status === "COMPLETED" && _winner !== "0"
              //   ? "visible"
              //   : "invisible"
            )}
          >
            {["COMPLETED", "SHOW_GAME"].includes(status) && <img
              src={
                (_winner !== "0" && _winner === players[0]._id
                  ? players[0]
                  : players[1]
                )?.option === 0
                  ? "https://i.imgur.com/weRfAqS.png"
                  : "https://i.imgur.com/maaXi2c.png"
              }
              className={merge("w-16 h-16 rounded-full")}
              alt={
                (_winner !== "0" && _winner === players[0]._id
                  ? players[0]
                  : players[1]
                )?.option === 0
                  ? "Heads"
                  : "Tails"
              }
            />}

            {startCountdown && <div className={merge("w-28 h-24 text-purple -mt-[98px]", startCountdown && "grid grid-cols-[1fr]")}>
              <span className={merge("relative font-black text-center top-[15px] pt-2 pl-1", 
                startCountdown && "before:animate-[count_5s_linear_forwards] before:content-[5]"
              )} />
        
              <svg className="relative z-50 col-start-1 row-start-1 top-2 -left-8 w-28" style={{ 'transform': 'rotateY(-180deg) rotateZ(-90deg)'}}>
                <circle r="36" cx="40" cy="40" className={merge(
                  "stroke-[2px] stroke-[white] fill-none ", 
                  startCountdown && "animate-[countdown_5s_linear_forwards]"
                )} style={{ strokeDasharray: '226px', strokeDashoffset: '0px', strokeWidth: '2px' }} />
              </svg>
            </div> }
          </div>

          {user ? (
            <Fragment>
              {(players[0] && players[0]._id) === user._id ? (
                <div className="flex space-x-2">
                  <Link
                    className="flex items-center justify-center gap-1 p-4 px-6 mx-auto font-bold text-center text-white rounded-lg bg-[#262535]"
                    // state={{ background: location, type: "view", id: _id }}
                    replace
                    to={`/duels?id=${_id}`}
                  >
                    WATCH DUEL
                  </Link>
                </div>
              ) : status === "ACTIVE" ? (
                <div className="flex space-x-2">
                  <Link
                    className="flex items-center justify-center gap-1 p-4 px-6 mx-auto font-bold text-center text-white rounded-lg bg-[#262535]"
                    // state={{ background: location, type: "view", id: _id }}
                    replace
                    to={`/duels?id=${_id}`}
                  >
                    WATCH DUEL
                  </Link>
                </div>
              ) : ["COMPLETED", "SHOW_GAME"].includes(status) || useless ? (
                <div className="flex space-x-2">
                  <Link
                    className="flex items-center justify-center gap-1 p-4 px-6 mx-auto font-bold text-center text-white rounded-lg bg-[#262535]"
                    // state={{ background: location, type: "view", id: _id }}
                    replace
                    to={`/duels?id=${_id}`}
                  >
                    WATCH DUEL
                  </Link>
                </div>
              ) : status === "PENDING" && (
                <div className="flex space-x-2">
                  <button
                    disabled={!user}
                    onClick={() => joinGame(_id)}
                    // state={{
                    //   background: location,
                    //   type: "view",
                    //   id: _id,
                    //   join: true,
                    // }}
                    // to="/duels"
                    //to="/duels"
                    className="flex items-center text-sm justify-center w-34 gap-1 p-4 px-6 mx-auto font-bold text-center text-white rounded-lg bg-[#448FFF]"
                  >
                    JOIN FOR{" "}
                    <img
                      src="images/currency.png"
                      alt="robux icon"
                      className="w-5 h-5 ml-1"
                    />{" "}
                    {price.toLocaleString()}
                  </button>
                  <Link
                    className="flex items-center justify-center w-24 gap-1 p-4 px-6 mx-auto font-bold text-center text-white rounded-lg bg-[#262535]"
                    // state={{ background: location, type: "view", id: _id }}
                    replace
                    to={`/duels?id=${_id}`}
                  >
                    WATCH
                  </Link>
                </div>
              )}
            </Fragment>
          ) : (
            <div className="flex space-x-2">
              <Link
                className="flex items-center justify-center gap-1 p-4 px-6 mx-auto font-bold text-center text-white rounded-lg bg-[#262535]"
                // state={{ background: location, type: "view", id: _id }}
                replace
                to={`/duels?id=${_id}`}
              >
                WATCH THIS DUEL
              </Link>
            </div>
          )}
        </div>
      </motion.div>
    )
  );
}

export default DuelCard;
