import reducers from './red';
import { thunk } from 'redux-thunk';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { applyMiddleware, compose, createStore } from 'redux';
import { composeWithDevTools } from '@redux-devtools/extension';

const store = createStore(reducers, JSON.parse(window.sessionStorage.getItem('_') || '{}'), composeWithDevTools(applyMiddleware(thunk)));

store.subscribe(() =>   window.sessionStorage.setItem('_', JSON.stringify(store.getState())))

export { store };