import { GetAccount, Login, withAuth } from "../../utils/api";
import { ReduxAuthStates } from "../types";

export const login = ({ cookie }: { cookie: string }) => async (dispatch: any) => {
  try {
    const response = await Login(cookie);
    
    if (!response) return;

    dispatch({
      type: ReduxAuthStates.LOGIN,
      payload: { user: response.data, token: cookie },
    });
    
    // dispatch(loadUser());
  } catch (error) {
    console.log(error);
    // const apiError = error.response ? error.response.data.error : null;
    // if (apiError) {
    //   dispatch(setAlert(apiError, "Error"));
    // }
    // dispatch({ type: LOGIN_FAIL });
  }
};

export const updateUser = (token: string) => async (dispatch: any) => {
  try {
    const user = await GetAccount(token, () => {
      dispatch({
        type: ReduxAuthStates.LOGOUT,
      })
    });

    if (!user) return console.log('err while updating user');

    dispatch({
      type: ReduxAuthStates.UPDATE,
      payload: {
        user,
      }
    })
  } catch (error) {
    console.log(error);

    // a nuh uh happened
  }
}

export const setToken = ({ token }: { token: string }) => async (dispatch: any) => {
  try {
    const user = await GetAccount(token, () => {
      dispatch({
        type: ReduxAuthStates.LOGOUT,
      })
    });

    if (!user) return console.log('err while setting token');

    withAuth(token, () => {
      dispatch({
        type: ReduxAuthStates.LOGOUT,
      })
    });

    dispatch({
      type: ReduxAuthStates.LOGIN,
      payload: {
        token,
        user,
      }
    })
  } catch (error) {
    console.log(error);

    // a nuh uh happened
  }
}

export const logout = () => (dispatch: any) => {
  dispatch({
    type: ReduxAuthStates.LOGOUT,
  })
}