import { useState, useEffect, Fragment } from "react";
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { API_URL, chat } from "../../../utils/api";
import { merge } from "../../../utils/tw";

import toast from 'react-hot-toast'
import { Tooltip } from 'react-tooltip'
import { AnimatePresence, motion } from "framer-motion";
import { numberFormatter, truncate } from "../../../utils/string";

interface StatElement {
    name: string;
    value: number;

    tw?: string; 

    /* 
        if you want to manipulate css for any stat, this is a tailwind value, to change css of value use the 
        `stat-value` class, 
        the name `stat-name`, 
        and to target the div use the `stat-name-with-dashes` class 
    */
}

export default function Profile({
    isAuthenticated: authenticated,
    isLoading: loading,
    originalLocation,
    id,
    closeModal,
    user: authUser,
}: any) {
    const location = useLocation();
    const navigate = useNavigate();
    const [user, setUser] = useState<any | null>(null);
    const [userId, setUserId] = useState(id);
    const [searchParams, setSearchParams] = useSearchParams();

    let temp: any = null;
    const [tipAmt, settipAmt] = useState(0);
    const [tipClicked, setTipClicked] = useState(false);
    const [mouseHovered, setMouseHovered] = useState(false);
    const [stats, setStats] = useState<StatElement[] | null>(null);

    useEffect(() => {
        const fetchUser = async () => {
            const request = await fetch(`${API_URL}/users/stats?id=${id}`)

            if (request.status !== 200) {
                closeModal()

                return toast.error('Something went wrong');
            }

            const response = await request.json();

            setUser(response);

            //Object.keys(response.stats) 
            setStats([
                (("balance" in response.stats) && ({
                    name: 'balance',
                    value: response.stats.balance,
                    tw: ''
                })) as any,
                {
                    name: 'profit',
                    value: <span className={merge(
                    )}>{response.stats.profit < 0 && '-'}${numberFormatter(Math.abs(response.stats.profit))}</span>,
                    tw: ''
                },
                {
                    name: 'highest win',
                    value: response.stats.highest_profit,
                    tw: ''
                },
                {
                    name: 'total lost',
                    value: response.stats.total_lost,
                    tw: ''
                },
                {
                    name: 'largest amount lost',
                    value: response.stats.largest_amount_lost,
                    tw: ''
                },
                {
                    name: 'total wagered',
                    value: response.stats.wagered,
                    tw: ''
                },
            ])

            return;
        }

        if (id) fetchUser();
    }, [id])

    const sendTip = () => {
        chat.emit('message', `/tip ${user.username} ${tipAmt}`)

        settipAmt(0)

        // toast.success('Tipped ')
    }

    return (
        <div className="w-[650px] h-[550px] flex flex-row mx-auto mt-18 self-center">
            <AnimatePresence>
                {   user && <Fragment>
                        <div className="flex flex-col w-full h-full text-white bg-primarylight my-auto p-8" style={{
                            'boxShadow': '0px 4px 4px 0 rgba(0,0,0,0.25)'
                        }}>
                
                            <button className="relative z-50 p-2 ml-auto mr-0" 
                                onClick={() => closeModal()}>
                                <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                    d="M19 5L5 19M19 19L5 5"
                                    stroke="white"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    />
                                </svg>
                            </button>

                            <div className="flex mx-auto w-full h-full flex-col space-y-5 px-8">
                                <div className="flex mx-auto space-x-5">
                                    <img src={user.avatar} alt={truncate(user.username)} className="w-24 h-24 border-2 border-purple rounded-md" />

                                    <div className="space-y-3">
                                        <div className="font-bold flex space-x-2 items-center">
                                            <div>
                                                <p className={merge(user.username.length > 12 && "text-lg", user.username.length < 12 && "text-3xl", "w-64 whitespace-normal truncate")}>{(user.username)}</p>
                                            </div>

                                            <div className="flex space-x-1.5">
                                                <p className="text-sm win-amount">W<span className="text-[10px] text-green align-super pl-0.5">{user.stats.gamesWon}</span></p>
                                                <p className="text-sm lost-amount">L<span className="text-[10px] text-red-900 align-super pl-0.5">{user.stats.gamesLost}</span></p>
                                                <p className="text-sm played-amount">P<span className="text-[10px] text-yellow-900 align-super pl-0.5">{user.stats.gamesPlayed}</span></p>
                                            </div>

                                            <div className="flex">
                                                {authUser && <button className="text-xs z-20 rounded-md border-2 border-none hover:bg-primarydark hover:border-primarydark px-2 py-0.5 tip-button" 
                                                onClick={() => (authUser && userId !== authUser._id) && setTipClicked(!tipClicked)}>
                                                    <img src='images/money-bag-white.png' className="w-5 h-6 fill-white"  alt='tip' />
                                                </button>}

                                                <AnimatePresence>
                                                    {
                                                        tipClicked && 
                                                        <motion.form
                                                            layout 
                                                            initial={{ x: 20, opacity: 0 }}
                                                            animate={{ x: 0, opacity: 1 }}
                                                            exit={{ x: -20, opacity: 0 }}
                                                            transition={{ duration: 0.2 }}
                                                            className={merge("flex bg-primary rounded-md -mr-[7.75rem] ml-1")}
                                                            onSubmit={(e) => {e.preventDefault(); (authUser && userId !== authUser._id) && sendTip()}}
                                                        >
                                                            <input 
                                                                type="text" 
                                                                placeholder="0" 
                                                                defaultValue={tipAmt} 
                                                                value={tipAmt}
                                                                onChange={(e) => (authUser && userId !== authUser._id) && settipAmt(e.target.value as any)}
                                                                className="bg-primaryextra border-2 w-24 rounded-md border-primary text-graytext text-right px-1.5" 
                                                            />
    
                                                            <button className="hover:bg-primarydark hover:border-primarydark px-2 py-0.5 rounded-md">
                                                                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M11.4669 3.72684C11.7558 3.91574 11.8369 4.30308 11.648 4.59198L7.39799 11.092C7.29783 11.2452 7.13556 11.3467 6.95402 11.3699C6.77247 11.3931 6.58989 11.3355 6.45446 11.2124L3.70446 8.71241C3.44905 8.48022 3.43023 8.08494 3.66242 7.82953C3.89461 7.57412 4.28989 7.55529 4.5453 7.78749L6.75292 9.79441L10.6018 3.90792C10.7907 3.61902 11.178 3.53795 11.4669 3.72684Z" fill="currentColor" fill-rule="evenodd" clip-rule="evenodd" />
                                                                </svg>
                                                            </button>
                                                        </motion.form>
                                                    }
                                                </AnimatePresence>
                                            </div>

                                            <Fragment>
                                                <Tooltip anchorSelect=".win-amount" place="top">
                                                    Won {user.stats.gamesWon} Game{user.stats.gamesWon !== 1 ? 's' : ''}
                                                </Tooltip>

                                                <Tooltip anchorSelect=".lost-amount" place="top">
                                                    Lost {user.stats.gamesLost} Game{user.stats.gamesLost !== 1 ? 's' : ''}
                                                </Tooltip>

                                                <Tooltip anchorSelect=".played-amount" place="top">
                                                    Played {user.stats.gamesPlayed} Game{user.stats.gamesPlayed !== 1 ? 's' : ''}
                                                </Tooltip>

                                                <Tooltip anchorSelect=".tip-button" place="top">
                                                    {(authUser && userId === authUser._id) ? 'Nuh Uh' : 'Tip User'}
                                                </Tooltip>
                                            </Fragment>
                                        </div>

                                        <div className="w-44">
                                            <div className="flex items-center w-full">
                                                <p className="text-xs font-black text-greytext">R$ PROFIT</p> 
                                                <p className={merge(
                                                    "ml-auto text-right text-md", 
                                                    user.stats.profit < 0 && "text-red-900", 
                                                    user.stats.profit > 0 && "text-green"
                                                )}>
                                                    {user.stats.profit < 0 && '-'}${numberFormatter(Math.abs(user.stats.profit))}
                                                </p>
                                            </div>

                                            <div className="flex items-center w-full">
                                                <p className="text-xs font-black text-greytext">R$ WAGERED</p> 
                                                <p className="ml-auto text-right text-md">${numberFormatter(user.stats.wagered)}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="text-center text-sm text-graytext items-center flex flex-col">
                                    <div className="flex items-center gap-1">
                                        <p 
                                            onMouseEnter={() => (authUser && authUser._id === userId) && setMouseHovered(!mouseHovered)} 
                                            onMouseLeave={() => (authUser && authUser._id === userId) && (temp = setTimeout(() => setMouseHovered(!mouseHovered), 1000))}
                                        >STATUS</p>

                                        <AnimatePresence>
                                            { mouseHovered && 
                                                <motion.button
                                                    layout 
                                                    initial={{ opacity: 0 }}
                                                    animate={{ opacity: 1 }}
                                                    exit={{ opacity: 0 }}
                                                    transition={{ duration: 0.2 }}
                                                    onClick={() => {
                                                        clearTimeout(temp);
                                                        setMouseHovered(true)
                                                    }}
                                                    className="hover:bg-primarydark hover:border-primarydark px-1.5 py-0.5 rounded-md -mr-[2.25rem] -mt-1"
                                                >
                                                    <img src="images/edit-text_white.png" className="w-5 h-5" />
                                                </motion.button>
                                            }
                                        </AnimatePresence>
                                    </div>
                                    <p className="text-xs tracking-tighter text-center font-normal">"{user.status}"</p>
                                </div>

                                <div className="">
                                    <div className="gap-2 grid grid-cols-2">
                                        <div className="w-[230px] space-y-2 profile-box">
                                            <p className="text-sm font-black tracking-wider">STATS</p>
                                            
                                            <div className="stats-list">
                                                {
                                                    stats && stats.map(({ name, value, tw }, index) => (
                                                        <div key={index} className={merge("flex items-center w-full indent-2", `stat-${name.replace(' ', '-')}`, tw)}>
                                                            <p className="text-xs font-normal text-greytext uppercase stat-name">{name}</p> 
                                                            <p className="ml-auto text-right text-xs stat-value tracking-tight">{typeof value !== 'number' ? value : `$${numberFormatter(value)}`}</p>
                                                        </div>
                                                    ))
                                                }
                                                {
                                                    !stats && <p className="text-greytext text-sm">No Information Found</p>
                                                }
                                            </div>
                                        </div>

                                        <div className="w-[230px] space-y-2 profile-box">
                                            <p className="text-sm font-black tracking-wider">STATS</p>
                                            
                                            <div className="stats-list">
                                                {
                                                    stats && stats.map(({ name, value, tw }, index) => (
                                                        <div key={index} className={merge("flex items-center w-full indent-2", `stat-${name.replace(' ', '-')}`, tw)}>
                                                            <p className="text-xs font-normal text-greytext uppercase stat-name">{name}</p> 
                                                            <p className="ml-auto text-right text-xs stat-value tracking-tight">${numberFormatter(value)}</p>
                                                        </div>
                                                    ))
                                                }
                                                {
                                                    !stats && <p className="text-greytext text-sm">No Information Found</p>
                                                }
                                            </div>
                                        </div>
                                        
                                        <div className="w-full space-y-2 profile-box col-span-2">
                                            <p className="text-sm font-black tracking-wider text-center">STATS</p>
                                            
                                            <div className="stats-list text-center">
                                                <p className="font-black m-auto">CHART HERE</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Fragment>
                }

                {
                    !user && <Fragment>
                        <div className="flex items-center justify-center w-full h-full space-y-10 text-white bg-primarylight" style={{
                            'boxShadow': '0px 4px 4px 0 rgba(0,0,0,0.25)'
                        }}>
                            <motion.div 
                                transition={{ 
                                    repeat: Infinity, 
                                    times: [0, 0.25, 0.5, 0.75, 1], 
                                    duration: 2.5,
                                    stiffness: 100,
                                    damping: 50,
                                    ease: "linear"
                                }}
                                animate={{ 
                                    opacity: [0.5, 0.75, 1, 0.75, 0.5], 
                                    scale: [1, 1.25, 1.5, 1.25, 1] 
                                }}
                                className="flex flex-col items-center self-center justify-center -mt-4 space-y-5 w-60 h-60"
                            >
                                <div className="text-center w-fit h-fit">
                                    <img src="/images/minilogo.png" alt="Logo" />
                                </div>
                            </motion.div>         
                        </div>
                    </Fragment>
                }
            </AnimatePresence>
        </div>
    )
}