export default function Maintenence() {
    return (
        <div className="h-screen md:w-screen overflow-y-hidden flex flex-col bg-primarylight">
            <div className="flex items-center flex-col space-y-5 justify-center my-auto">
                <div className="md:hidden text-center">
                    <img src="/images/minilogo.png" alt="Logo" />
                </div>

                <div className="md:block hidden">
                    <img src="/images/rbxlogo.png" alt="Logo" className="md:w-[150px]" />
                </div>

                <div className="flex flex-col">
                    <h1 className="lg:text-2xl text-lg text-wrap w-fit z-10 text-white font-bold text-center">
                        Our site is currently undergoing maintenance
                    </h1>
                </div>
            </div>
            
            <div className="flex [&_span_img]:w-16 z-10 text-center items-center justify-center">
                <span>
                    <img src="/images/Discord.svg" alt="DISCORD" />
                </span>
                <span>
                    <img src="/images/Twitter.svg" alt="TWITTER" />
                </span>
                <span>
                    
                </span>
            </div>
        </div>
    )   
}