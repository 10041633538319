import axios from "axios";
import { io } from "socket.io-client";

export const APP_URI = 
  window.location.hostname === "localhost" ? "http://localhost:5001" : window.location.hostname.includes('oblivion.bet') ? "https://www.oblivion.bet" : "https://e8fc-65-60-211-183.ngrok-free.app";

const NGROK_URIS = {
  PC: "https://567b-65-60-211-183.ngrok-free.app",
  MAC: "https://6980-98-103-165-210.ngrok-free.app",
  VPS: "https://cashmoneyhoes.delivr.cloud",
  LOCAL_SERVER: "https://idkwatiwanando.mostlikelyto.lol"
}

export const API_URL = NGROK_URIS.LOCAL_SERVER; //window.location.hostname === "localhost" ? "http://localhost:3000" : NGROK_URIS.VPS;

const WS_URL = API_URL
  .replace('http', 'ws')
  .replace('https', 'wss');

export const chat = io(`${WS_URL}/chat`)
export const drops = io(`${WS_URL}/live-drops`);

export const _ = [chat, drops];

export const withAuth = (token: string, logout: () => void) => _.forEach((socket) => { socket.auth = { token }; socket.emit('authn', token); socket.on('no-authn', logout) });

export const getItemImage = async (id: string): Promise<string> => {
  try {
    const request = await fetch(`${API_URL}/api/single?id=${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
    });

    // handle errosr
    
    const response = await request.json();

    // console.log(response.data);

    if (!(response.data.length || response.data)) return await getItemImage(id);

    return response.data[0].imageUrl;
  } catch (error) {
    console.log(error);
    return "";
  }
}

export const getBatchImages = async (limIds: string[]) => {
  try {
    const request = await fetch(`${API_URL}/api/batch`, {
      body: JSON.stringify({
        assets: limIds,
      }),
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })

    // handle errosr

    const resppnse = await request.json();

    return resppnse.data.map((r: any) => ({ id: r.targetId, uri: r.imageUrl }));
  } catch (error) {
    console.log(error);
    return []
  }
}

export const redirectToDiscord = () => 
  window.open(`https://discord.com/api/oauth2/authorize?${new URLSearchParams({
    client_id: process.env.DISCORD_OAUTH_ID as string,
    redirect_uri: `${APP_URI}/discord`,
    response_type: "code",
    scope: "identify email",
  }).toString()}`, "_parent")


export const GetPublicSettings = async () => {
  try {    
    const ok = await axios.get(`${API_URL}/api/public/settings`);

    return ok.data; 
  } catch (err) {
    return null
  }
}

export const ValidateCode = async (code: string) => {
  try {    
    const ok = await axios.get(`${API_URL}/api/ValidateCode?value=${code}`);

    return ok.data; 
  } catch (err) {
    return null
  }
}

export const Login = async (cookie: string) => {
  try {
    const ok = await axios.post(`${API_URL}/users/login`, {
      cookie
    });

    return ok.data; // { token:   string }
  } catch (error) {
    return null;
  }
}

export const GetAccount = async (token: string, logout?: () => void) => {
  try {
    const ok = await fetch(`${API_URL}/users/Account`, {
      headers: {
        Authorization: token
      }
    });

    if ((ok.status === 401)) {
      if (logout) return logout()

      return null;
    };

    const response = await ok.json();

    return response;
  } catch (error) {
    return null;
  }
}