// src/components/BottomNavbar/BottomNavbar.js

import React from "react";
import { connect } from "react-redux";
import { toggleChat } from "../../redux/actions/state";

function BottomNavbar({ toggleChat }: { toggleChat: () => void }) {
    return (
      <div className="fixed inset-x-0 bottom-0 z-10 text-white bg-black lg:hidden">
        <div className="flex items-center justify-around p-3">
          {/* Each NavLink is a navigation item */}
          <a href="/" className="flex flex-col items-center">
            <img src="/images/menu-icon.svg" alt="Menu" className="w-6 h-6" />
            <span className="text-xs">Menu</span>
          </a>
  
          <a href="/rewards" className="flex flex-col items-center">
            <img src="/images/rewards-icon.svg" alt="Rewards" className="w-6 h-6" />
            <span className="text-xs">Games</span>
          </a>
            
          <span className="flex flex-col items-center" onClick={toggleChat}>
            <img src="/images/chat-icon.svg" alt="Chat" className="w-6 h-6" />
            <span className="text-xs">Chat</span>
          </span>
        </div>
      </div>
    );
}
  
export default connect(
  null,
  { toggleChat }
)(BottomNavbar);