import { useRef, useState } from "react";
import { connect } from "react-redux"
import { Navigate, useNavigate, useParams, useSearchParams } from "react-router-dom";

import { motion } from "framer-motion";

import Login from './Login'
import Signup from "./Signup";
import { setToken } from "../../../redux/actions/auth";
import { CoinflipModals } from "../Coinflip";
import { merge } from "../../../utils/tw";
import Profile from "./Profile";

interface ModalProps {   
    isAuthenticated: boolean
    isLoading: boolean
    location: any
    token: string
    setToken: ({token}: {token: string}) => void
    user: any;
}

const ModalRoutes = ({
    isAuthenticated: authenticated,
    isLoading: loading,
    location,
    setToken,
    token,
    user,
}: ModalProps) => {
    const modalRef = useRef();
    const { modal } = useParams();
    const [imFucked, setOk] = useState(true)
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();

    const authProps = {
        isAuthenticated: authenticated,
        isLoading: loading,
        setToken
    }

    const otherRouteProps = {
        isAuthenticated: authenticated,
        isLoading: loading,
        type: modal,
        originalLocation: location,
        token,
        user: (authenticated && !loading) ? user : null,
        ...( modal === "profile" && { id: searchParams.get('id') }),
        closeModal: () => setOk(false),
    }
    
    return imFucked ? (
        <motion.div
            className="z-100"
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            //className="absolute top-0 left-0 z-10 flex items-center self-center justify-center m-auto" 
        >

            <div
                ref={modalRef as any}
                className={merge("fixed top-0 bottom-0 left-0 w-full h-full overflow-y-scroll justify-center items-center flex z-[298023]", modal === "duels" ? 'backdrop-blur-sm' : 'backdrop-blur')}
            >
                <div className="absolute w-full h-full" onClick={() => setOk(false)}></div>

                <div className="relative z-100">
                    { 
                        modal === "login" && (!authenticated && loading) ? 
                            <Login {...authProps} originalLocation={location} /> 
                        : modal === "signup" && (!authenticated && loading) ?
                            <Signup {...authProps} originalLocation={location} />
                        : modal === "duels" ? 
                            <CoinflipModals {...otherRouteProps} />
                        : modal === "profile" && searchParams.get('id') ? 
                            <Profile {...otherRouteProps} /> 
                        : <></>
                    }
                </div>
            </div>
        </motion.div>
    ) : <Navigate to="/duels" />
}

interface ConnectState {
    auth: {
        isAuthenticated: boolean,
        isLoading: boolean,
        token: string
        user: any;
    }
}

export default connect(
    (state: ConnectState) => ({ 
        isAuthenticated: state.auth.isAuthenticated, 
        isLoading: state.auth.isLoading,
        token: state.auth.token,
        user: state.auth.user,
    }),
    { setToken }
)((p: any) => (<ModalRoutes {...p} />));