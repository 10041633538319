import { useEffect, useState, useRef } from "react";
import { GetAccount, chat, drops } from "../../utils/api";
import { AnimatePresence, animate, motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

function WalletInfo({ user, token }: { user: any, token: string }) {
  const amountRef = useRef() as any;
  const navigate = useNavigate();

  const [diff, setDiff] = useState(0);
  const [last, setLast] = useState(user.balance ?? 0);
  const [balance, setBalance] = useState(user.balance ?? 0);

  useEffect(() => {
    // const node = amountRef.current;

    // const controls = animate(last, balance, {
    //   duration: 1,
    //   onUpdate(value) {
    //     node!.textContent = value.toLocaleString();
    //   }
    // });
    
    // return () => controls.stop();
  }, [last, balance])

  useEffect(() => {
    const balanceHandler = (newBalance: number) => 
      setBalance((prev: number) => {
        setLast(prev);

        setDiff(prev - newBalance)

        return newBalance
      });
    
      const refreshBalance = (type = 'none' as any) => {
        console.log('reset balance from', type);

        const getBalance = async () => {
          const acc = await GetAccount(token);

          if (!acc) return;

          balanceHandler(acc.balance)
        }

        if (token) getBalance();
      }

      const notifications = (payload: {
        type: string;
        id: string;
        amount: number;
  
        from?: string;
      }) => {
        if (payload.type.includes('game')) refreshBalance();
      }
    
    window.addEventListener('load', refreshBalance);
    window.addEventListener("beforeunload", refreshBalance);

    chat.on('balance.changed', balanceHandler)
    if (user && user._id !== null) drops.on(user._id, notifications);

    return () => {
      chat.off('balance.changed', balanceHandler);
      if (user && user._id !== null) drops.off(user._id, notifications);

      window.removeEventListener('load', refreshBalance);
      window.removeEventListener("beforeunload", refreshBalance);
    }
  }, []);

  useEffect(() => {
    if (diff !== 0) {
      setTimeout(() => {
        setDiff(0)
      }, 1000);
    }
  }, [diff])

  return (
    <div className="flex items-center justify-center md:justify-start">
      {/* Wallet Balance */}
      <div className="flex items-center space-x-2">
        <div className="flex flex-col">
          <span className="flex items-center p-3 px-3 md:px-5 text-white rounded-lg bg-[#0E0F18]">
            <img src="/images/currency.png" alt="Robux" className="w-5 h-5 mr-1 md:mr-2" />
            <p className="text-sm md:text-[16px]" ref={amountRef}>{balance.toLocaleString()}</p>
          </span>
          <AnimatePresence>
            {diff !== 0 && 
            <motion.p 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0, speed: 1 }}
            className="z-10 -mb-6 text-white mx-auto text-center">{last < balance ? '+' : '-'}{diff}</motion.p>}
          </AnimatePresence>
        </div>
        <button className="flex items-center p-3 px-5 text-white rounded-lg bg-lightblue blue-button" onClick={() => navigate('/deposit')}>
          {/* <img src="/images/wallet.svg" alt="Wallet" className="w-5 h-5" /> */}
          <span className="hidden md:inline font-semibold text-sm">DEPOSIT</span>
        </button>
      </div>
    </div>
  );
}
  
export default WalletInfo;
  