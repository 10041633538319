import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Fragment } from "react/jsx-runtime";
import { merge } from "../../../utils/tw";
import { API_URL } from "../../../utils/api";

import toast from 'react-hot-toast';

interface LoginProps {
    isAuthenticated: boolean
    isLoading: boolean
    originalLocation: any,
    setToken: ({token}: {token: string}) => void
}

export default function Signup({
    isAuthenticated: authenticated,
    isLoading: loading,
    originalLocation,
    setToken
}: LoginProps) {
    const navigate = useNavigate();
    const location = useLocation();
    const [email, setEmail] = useState("");
    const [enterAUsername, __] = useState(false);
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    useEffect(() => {
        if (location.state?.return && location.state?.payload) {
            __(!enterAUsername);
        }
    }, [])

    const finishOnSubmit = async () => {
        if (enterAUsername) {
            const Return = location.state?.return;
            
            const request = await fetch(`${API_URL}/users/validate`, {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'x-final-auth': Return,
                },
                body: JSON.stringify({
                    type: "_final",
                    code: username,
                })
            });
            if (request.status !== 200) {
                // something happnd;

                console.log('no fun anymore');

                return;
            }

            const response = await request.json();

            if (!("token" in response)) {
                // why are we here
            } else {
                setToken({ token: response.token });

                console.log('finished signup')
    
                return navigate('/')
            }
        } else {
            const request = await fetch(`${API_URL}/users/Create`, {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
                body: JSON.stringify({
                    username,
                    password,
                    email
                }),
            });

            if (request.status === 401) {
                const response = await request.json();

                return toast.error(response.message, {
                    position: 'top-right'
                })
            } else if (request.status === 500) return toast.error('Something went wrong, please try again later.')
            
            const response = await request.json();
            
            if (!("token" in response)) {
                console.log('no token in resp', response)

                return toast.error('Something went wrong, please try again later.')
            } else {
                toast.success('Signed Up! Please login..', {
                    position: 'top-right'
                })

                return navigate('/login', {
                    state: {
                        background: originalLocation,
                    }
                })
            }
        }
    }

    return (
        <div className="w-[650px] h-[550px] flex flex-row mx-auto mt-32 self-center">
            <div className="h-full w-full bg-primarylight space-y-10 rounded-tl-[20px] rounded-bl-[20px] text-white" style={{
                'boxShadow': '0px 4px 4px 0 rgba(0,0,0,0.25)'
            }}>
                <div className={merge(`flex flex-col justify-center p-8`, !enterAUsername ? 'space-y-3' : 'space-y-[30%]')}>
                    <div className="flex space-x-3">
                        <Link to="/signup" state={{ background: originalLocation }} className="text-xl font-bold">Sign Up</Link>
                        <Link to="/login" state={{ background: originalLocation }} className="mt-2 text-xs font-medium">Login</Link>
                    </div>

                    <div>
                        <div className="flex flex-col justify-center">
                            <form className="space-y-2.5" onSubmit={(e) => {e.preventDefault(); finishOnSubmit() }}>
                                { (!enterAUsername) && 
                                    <Fragment>
                                        <div className="space-y-0.5">
                                            <p className="text-xs text-[#949494]">Email</p>
                                            <input type="email" required placeholder="" className="w-full h-10 rounded-md bg-[#151515] border-black border-[1px] p-2 text-sm font-medium" onChange={(e) => setEmail(e.target?.value)} value={email} />
                                        </div>

                                        <div className="space-y-0.5">
                                            <p className="text-xs text-[#949494]">Username</p>
                                            <input type="text" required placeholder="" className="w-full h-10 rounded-md bg-[#151515] border-black border-[1px] p-2 text-sm font-medium" onChange={(e) => setUsername(e.target?.value)} value={username} />
                                        </div>

                                        <div className="space-y-0.5">
                                            <p className="text-xs text-[#949494]">Password</p>
                                            <input type="password" required placeholder="" className="w-full h-10 rounded-md bg-[#151515] border-black border-[1px] p-2 text-sm font-medium" onChange={(e) => setPassword(e.target?.value)} value={password} />
                                        </div>
                                    </Fragment>
                                }

                                { enterAUsername && 
                                    <Fragment>
                                        <p className="text-[#949494] font-normal">Enter a username to continue</p>
                                        
                                        <div>
                                            <input type="text" required placeholder="" className="w-full h-10 rounded-md bg-[#151515] border-black border-[1px] p-2 text-sm font-medium" onChange={(e) => setUsername(e.target?.value)} value={username} />
                                        </div>
                                    </Fragment>
                                }
                                
                                <div>
                                    <button className="w-full bg-[#2E2E2E] hover:bg-[#3E3E3E] h-12 rounded-md text-xs font-semibold" type="submit">
                                        Continue
                                    </button>
                                </div>
                            </form>

                            { (!enterAUsername) && 
                                <Fragment>
                                    <div className="seperator w-[200px] h-0.5 bg-[#535353] rounded-md self-center flex my-4" />

                                    <div className="space-y-2 text-xs font-semibold">
                                        <div className="flex space-x-3">
                                            <button className="w-full bg-[#151515] h-12 rounded-md border-[#2E2E2E] border-2 px-6 flex items-center" disabled={enterAUsername}>
                                                <span className="w-full">
                                                    <img src="/images/google_white.svg" alt='google icon' />
                                                </span>

                                                <p className="text-right">
                                                    Google
                                                </p>
                                            </button>

                                            <button className="w-full bg-[#151515] h-12 rounded-md border-[#2E2E2E] border-2 px-6 flex items-center" disabled={enterAUsername} onClick={() => navigate('/discord', {
                                                state: {
                                                    to: '/signup'
                                                }
                                            })}>
                                                <span className="w-full">
                                                    <img src="/images/discord_white.svg" alt='discord icon' />
                                                </span>

                                                <p className="text-right">
                                                    Discord
                                                </p>
                                            </button>
                                        </div>
                                        <div>
                                            <button className="w-full bg-[#151515] h-12 rounded-md border-[#2E2E2E] border-2 px-6 flex items-center" disabled={enterAUsername}>
                                                <span className="w-full">
                                                    <img src="/images/roblox_white.svg" alt='roblox icon' />
                                                </span>

                                                <p className="text-right">
                                                    Roblox
                                                </p>
                                            </button>
                                        </div>
                                    </div>
                                </Fragment>
                            }
                        </div>

                        <div className={merge("flex justify-center", enterAUsername ? 'my-6' : '')}>
                            <p className={merge("text-xs font-normal text-center", enterAUsername ? 'w-full' : 'w-[325px]')}>
                                By continuing, you acknowledge this message and agree that you are over the age of 18, and consent to our{' '}
                                <Link to="/legal#tos" className="text-[#BFA2A2] underline" state={{ background: originalLocation }}>
                                    Terms of Service
                                </Link> and{' '}
                                <Link to="/legal#privacy" className="text-[#BFA2A2] underline" state={{ background: originalLocation }}>
                                    Privacy Policy
                                </Link>.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="h-full w-[200px] bg-[#151720] rounded-tr-[20px] rounded-br-[20px] flex justify-center" style={{
                'boxShadow': '0px 4px 4px 0 rgba(0,0,0,0.25)'
            }}>
                <div className="flex flex-col justify-center py-0 -mt-5 space-y-1">
                    <div>
                        <img src="/images/rbxlogo.png" className="w-24 ml-1" alt="logo" />
                    </div>
                    <div>
                        <img src="/images/lelouch_vert.png" className="rounded-[10px] object-cover h-[425px] w-[105px]" alt='banner' style={{
                            'boxShadow': '0px 4px 4px 0 rgba(0,0,0,0.25)'
                        }} />
                    </div>
                </div>
            </div>
        </div>
    )
}