import { motion, useSpring } from "framer-motion";
import { useRef, useState, useEffect } from "react";

export default function NotFound({ text }: { text: string }) {
    const ref = useRef(null);

    const rfactor = 30
    const sp = {
        type: "spring",
        stiffness: 300,
        damping: 40,
    }

    const [rXAxis, setXAxis] = useState(0)
    const [rYAxis, setYAxis] = useState(0)

    const x = useSpring(0, sp)
    const y = useSpring(0, sp)

    useEffect(() => {
        document.title = text
    }, [text]);

    useEffect(() => {
        x.set(-rXAxis)
        y.set(rYAxis)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rXAxis, rYAxis])

    const handle = (ev: any) => {
        let ele = ref.current as any;

        let rect = ele.getBoundingClientRect();
        let wid = rect.width;
        let hei = rect.height;

        let centx = wid / 2
        let centy = hei / 2

        let moux = ev.clientY - rect.y - centy
        let mouy = ev.clientX - rect.x- centx

        let dx = (moux / wid) * rfactor
        let dy = (mouy / hei) * rfactor

        setXAxis(dx)
        setYAxis(dy)
    }

    return (
        <motion.div 
            style={{
                perspective: "1200px",
                transformStyle: "preserve-3d",
            }}
            transition={sp}
            className="h-screen md:w-screen overflow-y-hidden flex flex-col bg-primarylight"
        >
            <motion.div 
                transition={sp}
                ref={ref}
                onMouseMove={handle} 
                onMouseLeave={() => { setXAxis(0);setYAxis(0)}}
                className="flex items-center flex-col space-y-5 justify-center my-auto"
            >
                <div className="md:hidden text-center">
                    <img src="/images/minilogo.png" alt="Logo" />
                </div>

                <motion.div 
                    className="md:block hidden" 
                    transition={sp}
                    whileHover={{ scale: 1.1 }} 
                    style={{ rotateX: x, rotateY: y }}
                >
                    <div style={{ perspective: "1200px", transformStyle: "preserve-3d", width: "100%", height: "100%" }}>
                        <img src="/images/rbxlogo.png" alt="Logo" className="md:w-[150px]" />
                    </div>
                </motion.div>

                <div className="flex flex-col space-y-2">
                    <h1 className="lg:text-5xl text-lg text-wrap lg:blur-sm blur-[2px] w-fit z-10 text-white font-bold text-center">
                        "{text.toLocaleUpperCase()}"
                    </h1>

                    <button 
                        className="text-white font-semibold text-lg hover:text-opacity-80"
                        onClick={() => window.history.back()}>go back?</button>
                </div>
            </motion.div>
        </motion.div>
    )   
}