
import toast from "react-hot-toast";
import { connect } from "react-redux";
import { merge } from "../../utils/tw";
import { scaleNum } from "../../utils/string";
import DuelCard from "../DuelCard/DuelCard";
import { Fragment, useEffect, useMemo, useState, useReducer } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { AnimatedSprite, Container, Stage } from "@pixi/react";
import {
  Link,
  Navigate,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { API_URL, drops, getBatchImages } from "../../utils/api";

import moment from "moment";
import { Helmet } from 'react-helmet'

export default function WalletActions({ user, token, isAuthenticated, type }: any) {
  const location = useLocation();
  const navigate = useNavigate();

  const [selected, setSelected] = useState<"withdraw" | "deposit">(type);

  const methods = [
    {
        section: "Roblox",
        options: [
            {
                name: "Limiteds",
                subtitle: "Adurite",
                image: "images/dominus.png",
                gradient: "bg-gradient-to-b from-graytext to-primary"
            },
            {
                name: "Robux",
                subtitle: "Adurite",
                image: "images/currency.png",
                gradient: "bg-gradient-to-b from-graytext to-primary"
            },
        ]
    },
    
    {
        section: "Cryptocurrencies",
        options: [
            {
                name: "Bitcoin",
                subtitle: "5050",
                image: "images/btc.png",
                gradient: "bg-gradient-to-t from-primary via-[#FF7A00] via-65% to-primary"
            },
        ]
    }
  ]

  return (
    <div className="w-full my-8 space-y-8">
        <div>
            <div className="text-white flex flex-row space-x-2 [&>button]:px-5 [&>button]:py-2 [&>button]:rounded-md bg-[#171825] w-fit px-2.5 py-3 rounded-md">
                <button className={merge(selected === "deposit" && "bg-[#27293E]")} onClick={() => setSelected("deposit")}>Deposit</button>
                <button className={merge(selected === "withdraw" && "bg-[#27293E]")} onClick={() => setSelected("withdraw")}>Withdraw</button>
            </div>
        </div>

        {selected === "deposit" && <div className="space-y-6">
            {methods.map((method, index) => (
                <div key={index} className="space-y-3"> 
                    <p className="text-white text-xl font-bold">{method.section}</p>

                    <div className="flex space-x-4">
                        {method.options.map((option, ind) => (
                            <button key={ind} className={merge("flex p-3 select-none rounded-lg w-36 h-36 flex-col text-center items-center text-white", option.gradient)}>
                                <img src={option.image} alt={option.name} className="h-20"/>

                                <p className="text-base font-semibold text-[#BEBFC7]">{option.name}</p>
                                <p className="font-light tracking-wide text-xs text-[#6D718F]">{option.subtitle}</p>
                            </button>
                        ))}
                    </div>
                </div>
            ))}
        </div>}
    </div>
  );
}
