import { motion } from "framer-motion";
import { useEffect } from "react";
export default function Loading() {
    const sp = {
        type: "spring",
        stiffness: 300,
        damping: 40,
    }

    useEffect(() => {
        document.title = "..."
    }, []);

    return (
        <motion.div 
            style={{
                perspective: "1200px",
                transformStyle: "preserve-3d",
            }}
            transition={sp}
            className="h-screen md:w-screen overflow-y-hidden flex flex-col bg-primarylight"
        >
            <motion.div 
                transition={{ 
                    repeat: Infinity, 
                    times: [0, 0.25, 0.5, 0.75, 1], 
                    duration: 2.5,
                    stiffness: 100,
                    damping: 50,
                    ease: "linear"
                }}
                animate={{ 
                    opacity: [0.5, 0.75, 1, 0.75, 0.5], 
                    scale: [1, 1.25, 1.5, 1.25, 1] 
                }}
                className="flex items-center flex-col space-y-5 justify-center w-60 h-60 m-auto"
            >
                <div className="text-center w-fit h-fit">
                    <img src="/images/minilogo.png" alt="Logo" />
                </div>
            </motion.div>
        </motion.div>
    )   
}