import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import { connect } from "react-redux";
import * as d3 from "d3";

import ApexCharts from 'react-apexcharts'
import { API_URL, drops } from "../../utils/api";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { merge } from "../../utils/tw";
import { funnyNumberValidation, scaleNum, truncate } from "../../utils/string";
import { isNumber } from "util";

const Donut = ({ data }: { data: any[] }) => {
    const ddc = useRef(null);

    useEffect(() => {
        const element = ddc.current;

        const boxSize = 500;
        const width = 600;
        const height = 400;
        const innerRadius = 130;
        const outerRadius = Math.min(width, height) / 2;

        const imgSize = 
            data.length >= 10 ? 32 :
            data.length >= 15 ? 24 :
            data.length >= 25 ? 12 :
            data.length >= 30 ? 6
            : 48;
        
        d3.select(element).select("svg").remove();

        const svg = d3
            .select(element)
            .append("svg")
            .attr("preserveAspectRatio", "xMidYMid meet")
            .attr("height", "100%")
            .attr("width",  "100%")
            .attr("viewBox", `0 0 ${boxSize} ${boxSize}`)
            .append("g")
            .attr("transform", `translate(${boxSize / 2}, ${boxSize / 2})`);

        svg.append("text")
            .attr("dy", ".35em")
            .attr("text-anchor", "middle")
            .attr("style","font-family")
            .attr("font-size","25")
            .attr("fill","#FFFFFF")
            .attr("dy", "0em")
            .text(`R$ ${data.reduce((a: any, b: any) => b.value + a, 0).toLocaleString()}`)

        svg.append("text")
            .attr("dy", ".35em")
            .attr("text-anchor", "middle")
            .attr("style","font-family")
            .attr("font-size","12")
            .attr("y","8")
            .attr("fill", "rgba(255, 255, 255, 0.5)")
            .attr("dy", "1em")
            .text(`${scaleNum(data.reduce((a: any, b: any) => b.value + a, 0))}`)

        const arcGenerator = d3.arc().innerRadius(innerRadius).outerRadius(outerRadius);

        const pieGenerator = d3.pie().value((d: any) => d.value as any);

        const arcs = svg.selectAll().data(pieGenerator(data)).enter();
        
        arcs
            .append("path")
            .attr("d", arcGenerator as any)
            .style("fill", (d: any) => d.data.color);

        arcs.append("g")
            .attr("transform", (d: any) => `translate(${arcGenerator.centroid(d)})`)
            .append("svg:image")
            .attr("clip-path", "inset(0% round 1000px)")
            .attr("xlink:href", (d: any) => d.data.name)
            .attr("width", imgSize)
            .attr("height", imgSize)
            .attr("x", -1 * imgSize / 2)
            .attr("y", data.length < 2 ? -14.75 * imgSize / 2 : -1 * imgSize / 2);
    }, [data]);

    // useEffect(() => {
    //     const element = ddc.current;

    //     const imgSize = 
    //         data.length >= 10 ? 32 :
    //         data.length >= 15 ? 24 :
    //         data.length >= 25 ? 12 :
    //         data.length >= 30 ? 6
    //         : 48;

    //     const newp = d3
    //         .pie()
    //         .value((d: any) => d.value)
    //         .sort(null)(data);
        
    //     const path = d3.select(element)
    //         .select("svg")
    //         .select("g")
    //         .selectAll("path")
    //         .data(newp);
        
    //     const arcsg = d3.arc()
    //         .innerRadius(130)
    //         .outerRadius(Math.min(600, 400) / 2);
                
    //     // path.select("g")
    //     //     .attr("transform", (d: any) => `translate(${arcsg.centroid(d)})`)
    //     //     .append("svg:image")
    //     //     .attr("clip-path", "inset(0% round 1000px)")
    //     //     .attr("xlink:href", (d: any) => d.data.name)
    //     //     .attr("width", imgSize)
    //     //     .attr("height", imgSize)
    //     //     .attr("x", -1 * imgSize / 2)
    //     //     .attr("y", data.length < 2 ? -14.75 * imgSize / 2 : -1 * imgSize / 2);
        
    //     path
    //         .transition()
    //         .duration(500)
    //         .attr("d", path as any)
    // }, [data])
  
    return (
        <div ref={ddc} className="w-full h-full text-white" />
    )
}

export function Jackpot({
    user,
    token,
}: any) {
    const navigate = useNavigate();
    const [amountBet, setAmountBet] = useState(1000);
    const [game, setGame] = useState<any | null>(null);
    const [joinDebounce, setJoinDebounce] = useState(false);

    const remappedPlayers = (game && game.players.length) ? game.players.map((plr: any) => ({
        name: plr.avatar,
        value: parseInt(plr.amountBet),
        ...plr,
    })) : [];

    useEffect(() => {
        const fetchJackpot = async () => {
            const request = await fetch(`${API_URL}/jackpot/current`);

            if (request.status === 500) return toast.error("Something went wrong");
            if (request.status !== 200) return;
            
            const response = await request.json();

            if ("jackpot" in response) setGame(response.jackpot);
        }

        const removeJackpotGame = () => {console.log('jackpot ended');setGame(() => null);}
        const updateJackpotGame = (newGame: any) => setGame(() => newGame);

        fetchJackpot();

        drops.on('new-jackpot', updateJackpotGame);
        drops.on('remove-jackpot', removeJackpotGame);
        drops.on('updated-jackpot', updateJackpotGame);

        return () => {
            drops.off('new-jackpot', updateJackpotGame);
            drops.off('remove-jackpot', removeJackpotGame);
            drops.off('updated-jackpot', updateJackpotGame);
        }
    }, []);
        
    const joinJackpot = async () => {
        if (joinDebounce) return;
        setJoinDebounce(true);

        if (typeof(amountBet) !== "number")
            return toast.error("Amount must be a number", {
              position: "top-right",
            });

        const request = await fetch(`${API_URL}/jackpot/join`, {
            method: 'POST',
            headers: {
                Authorization: token,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ amount: parseInt(amountBet as unknown as string) })
        })

        if ([401, 403].includes(request.status)) {
            setJoinDebounce(false);

            return toast.error((await request.json()).error, {
                position: "top-right"
            });
        }
        if (request.status === 500) {
            setJoinDebounce(false);

            return toast.error("Something went wrong", {
                position: "top-right"
            });
        }

        setJoinDebounce(false);

        return toast.success('Joined Jackpot!', {
            position: "top-right"
        })
    }

    return (
        <div className="flex w-full h-full p-10 md:space-x-5 flex-col md:flex-row">
            <div className="md:w-[750px] w-full h-[600px] text-white">
            { !game && <p className="text-xl text-center text-white">
                No Current Game
                </p>
            }

                { remappedPlayers.length >= 1 && <Donut data={remappedPlayers} />}
            </div>
            
            <div className="w-[600px] h-full">
                <div className="flex flex-row w-full mx-2 my-2 space-x-2 xl:space-x-4">
                    <div className="flex items-center px-2 -ml-2 text-xs w-72 bg-primarylight text-graytext rounded-xl">
                        <span className="justify-start w-full">
                            MIN: 1K R$
                        </span>
                        
                        <div className="justify-center w-full mr-2 -ml-8">
                            <input
                                className="h-1 mb-2 outline-none bg-graytext"
                                type="range"
                                min={1000}
                                max={100_000_000}
                                step={10000}
                                value={amountBet}
                                onChange={event => setAmountBet(event.target.valueAsNumber)}
                            />
                        </div>

                        <span className="justify-end w-full -mr-8">
                            MAX: 100M
                        </span>
                    </div>

                    <div className="flex space-x-1">
                        <div className="flex text-[#A4C8FF] bg-primarylight py-3 px-6 rounded-xl font-normal xl:w-52">
                            <span className="pr-1 -ml-2">R$</span>
                            
                            <input type="text" value={amountBet} onChange={(e) => setAmountBet(e.target.value as any)} className="w-24 mx-3 border-none max-xl:text-xs text-md max-xl:w-14 bg-primarylight" />

                            <div className="flex items-center space-x-1.5 text-xs -mr-3 xl:justify-end xl:text-md">
                                <p onClick={() => setAmountBet((pr) => pr * 2)}>x2</p>
                                <p onClick={() => setAmountBet((pr) => pr / 2)}>/2</p>
                            </div>
                        </div>

                        <button className="text-white bg-[#448FFF] font-bold px-4 py-2 rounded-xl" onClick={() => joinJackpot()}>
                            JOIN
                        </button>
                    </div>
                </div>
                
                <ul className="flex flex-col items-center w-full overflow-y-scroll h-[535px] ">
                    {remappedPlayers.sort((a: any, b: any) => b.value - a.value).map((player: any, index: any) => (
                        <li className="flex items-center self-center w-full h-20 px-6 py-2.5 my-1 bg-primarylight rounded-2xl" key={index}>
                            <div className="flex items-center w-full space-x-8 text-white">
                                <img 
                                    onClick={() => navigate(`/profile?id=${player._id}`)}
                                    src={player.avatar ?? "images/user.png"} 
                                    alt={truncate(player.username)} 
                                    className={merge(`flex-shrink-0 object-cover border-4 rounded-full h-14 w-14 flex-grow-0`)}
                                    style={{
                                        borderColor: player.color
                                    }}
                                />

                                <p className="w-48 truncate">{player.username}</p>
                            </div>

                            <div className="w-full text-right text-white ">
                                <p className={`font-bold`} 
                                    style={{
                                        color: player.color
                                    }}>
                                    <span>
                                        R$
                                    </span>

                                    {player.value.toLocaleString()}
                                </p>
                                <p className="font-normal">{((player.value / game.reward) * 100).toFixed(2)}%</p>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    )   
}

export default connect((state: any) => ({ user: (state.auth.isAuthenticated && !state.auth.isLoading) ? state.auth.user : null, isAuthed: state.auth.isAuthenticated, isLoading: state.auth.isLoading, token: state.auth.token }))(Jackpot)