// src/components/LiveChat/LiveChat.js

import { motion } from "framer-motion";
import { API_URL, chat } from "../../utils/api";
import { useState, useEffect, useRef } from "react";
import moment from "moment";

import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import { truncate } from "../../utils/string";

interface ChatProps {
  user: boolean | any;
  token: string;
}

interface Message {
  id: string;
  username: string;
  content: string;
  timestamp: string;
  avatar: string | null;
  sender: string;
}

function LiveChat({ user, token }: ChatProps) {
  const end = useRef<HTMLDivElement>(null);
  const [newMessage, setNewMessage] = useState("");
  const [usersOnline, setUsersOnline] = useState(0);
  const [alert, setAlert] = useState<null | { username: string; amount: number; msg?: string | null; }>(null);
  const [messages, setMessages] = useState<Message[]>([]);
  // const [mentionedUsers, setMentionedUser] = useState<any[]>([]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const location = useLocation()
  const navigate = useNavigate()

  // lol. ignore the 4 useEffects, im bored ;; make that 5

  useEffect(() => {
    const fetchMessages = async () => {
      const request = await fetch(`${API_URL}/api/messages`);

      if (request.status !== 200) return;

      const response = await request.json();

      if (!("messages" in response)) return;

      setMessages(
        response.messages.reverse().map((message: any) => {
          if (typeof message.timestamp === "number") {
            return {
              id: message.id,
              username: message.username,
              content: message.content,
              avatar: message.avatar,
              sender: message.sender,
              timestamp: moment(message.timestamp).format("HH:MM"),
            };
          } else return message;
        })
      );
    };

    fetchMessages();
  }, []);

  useEffect(() => {
    end.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);
  
  useEffect(() => {
    const messageHandler = ({
      content,
      createdAt,
      id,
      username,
      avatar,
      sender,
    }: any) =>
      setMessages((mess) => [
        ...mess,
        {
          content,
          timestamp: moment(createdAt).format("HH:MM"),
          id,
          username,
          avatar,
          sender,
        },
      ]);

    const forceUpdateHandler = (messages: Message[]) =>
      setMessages(
        messages.reverse().map((message: any) => {
          if (typeof message.timestamp === "number") {
            return {
              id: message.id,
              username: message.username,
              content: message.content,
              sender: message.sender,
              timestamp: moment(message.timestamp).format("HH:MM"),
            };
          } else return message;
        })
      );

    const successHandler = (message: string) =>
      toast.success(message, {
        position: "top-right",
      });

    const errorHandler = (message: string) =>
      toast.error(message, {
        position: "top-right",
      });

    const watchPeopleConnected = (totalConnected: number) =>
      setUsersOnline(totalConnected);

    chat.on("message", messageHandler);
    chat.on("chat-error", errorHandler);
    chat.on("chat-success", successHandler);
    chat.on("forceUpdate", forceUpdateHandler);
    chat.on("users-online", watchPeopleConnected);

    return () => {
      chat.off("message", messageHandler);
      chat.off("chat-error", errorHandler);
      chat.off("chat-success", successHandler);
      chat.off("forceUpdate", forceUpdateHandler);
      chat.off("users-online", watchPeopleConnected);
    };
  }, []);

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
      console.log(windowWidth);
    }

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   if (!newMessage.includes('@')) return;

  //   const messageSplice = newMessage.split(' ');

  //   const needThis = messageSplice.find((msg) => msg.startsWith('@'));

  //   const usernameToFind = needThis && needThis.slice(0, 1);

  //   if (!usernameToFind) return;

  //   chat.emit('findUserByRegex', usernameToFind);

  //   const updateMentions =
  //     (user: any) => setMentionedUser((users) => users.some((u: any) => u._id === user._id) ? users : [...users, {...user, mentionNumber: mentionedUsers.length + 1}]);
    
  //   chat.on('findUserByRegex.res', updateMentions);

  //   return () => {
  //     chat.off('findUserByRegex.res', updateMentions);
  //   }
  // }, [newMessage])

  const isMobile = windowWidth <= 1100;

  const handleSendMessage = () => {
    try {
      chat.timeout(5000).emitWithAck("message", newMessage).catch(console.log);
    } catch (err) {
      // add toast
    }

    setNewMessage("");
  };

  return (
    <motion.aside
      aria-label="LiveChat"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0, speed: 1 }}
      style={{ height: `calc(100vh - (12vh + ${isMobile ? "4rem" : "2rem"}))` }}
      className="absolute flex flex-col justify-between w-full overflow-hidden text-white lg:relative lg:flex lg:w-fit bg-[#100F19] lg:space-y-4 p-2.5 z-[5]">
      <div className="flex items-center flex-none px-3 py-3 border-gray-700 rounded-lg bg-primaryextra lg:mt-5">
        <p className="text-md font-medium flex-grow text-[#A6A6A6]">Messages</p>

        <p className="flex-grow w-fit justify-end text-right flex items-center text-[#A6A6A6]">
          {usersOnline.toLocaleString()}
          <span className="w-2 h-2 bg-green rounded-full ml-1.5"></span>
        </p>
      </div>

      {alert && <div className="w-full relative h-12 flex flex-raw items-center px-2 rounded-md bg-primaryextra ">
        <p className="text-white break-words whitespace-normal w-full h-full py-3.5 text-center text-[13px]">
          {!alert.msg && 
            <>🤑 
              <span>{alert.username}</span> was tipped <span className="font-bold text-[#FF9900]">${alert.amount.toLocaleString()}
              </span>! 🤑
            </>
          }

          {alert.msg && <>{alert.msg}</>}
        </p>
      </div>}

      <ul className="flex-1 p-1.5 space-y-2 overflow-y-auto">
        {messages.map((message) => (
          <li
            key={`${message.id}`}
            className="flex flex-col"
          >
            <div className="flex space-x-2">
              <img
                className="w-9 h-9 rounded-full border-2 hover:border-lightblue transition-colors border-[#100F19]"
                src={message.avatar ? message.avatar : `/images/user.png`}
                alt={`${truncate(message.username)}`}
                onClick={() => navigate(`/profile?id=${message.sender}`, {
                  state: {
                    background: location
                  }
                })}
              />

              <div className="flex items-center justify-between">
                <span className="font-normal w-48 truncate">{message.username}</span>
              </div>
            </div>
            <div>
              <p className="w-64 p-2 pl-0 text-sm text-left text-gray-400 break-words whitespace-normal">
                {message.content}
              </p>
            </div>
          </li>
        ))}

        <div ref={end} />
      </ul>

      <div>
        {user !== null && (
          <form
            className="flex rounded-lg"
            onSubmit={(e) => {
              e.preventDefault();
              handleSendMessage();
            }}
          >
            <input
              type="text"
              className="flex-1 px-2 py-4 text-sm text-white rounded-lg bg-primarylight focus:outline-none"
              placeholder="Send a message!"
              value={newMessage}
              disabled={user === null}
              onChange={(e) => setNewMessage(e.target.value)}
            />
            <button
              className="hidden p-2 px-5 ml-2 text-white rounded-lg bg-purple"
              disabled={user === null}
              type="submit"
            >
              <img src="/images/send.svg" alt="Send" className="w-6 h-6" />
            </button>
          </form>
        )}

        {user === null && (
          <div>
            <p className="text-center text-gray-500">
              You must login in order to chat
            </p>
          </div>
        )}
      </div>
    </motion.aside>
  );
}

export default LiveChat;
