import { ValidateCode } from "../../utils/api";
import { motion, useSpring } from "framer-motion";
import React, { useRef, useState, useEffect } from "react";

export default function Testing({ children }: { children: React.ReactNode }) {
    const ref = useRef(null);

    const [val, setVal] = useState("");
    const [renderIt, setRenderIt] = useState(false);
    const [ disabled, disableTextBox ] = useState(false)

    const rfactor = 30
    const sp = {
        type: "spring",
        stiffness: 300,
        damping: 40,
    }

    const [rXAxis, setXAxis] = useState(0)
    const [rYAxis, setYAxis] = useState(0)

    const x = useSpring(0, sp)
    const y = useSpring(0, sp)

    useEffect(() => {
        x.set(-rXAxis)
        y.set(rYAxis)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rXAxis, rYAxis])

    const handle = (ev: any) => {
        let ele = ref.current as any;

        let rect = ele.getBoundingClientRect();
        let wid = rect.width;
        let hei = rect.height;

        let centx = wid / 2
        let centy = hei / 2

        let moux = ev.clientY - rect.y - centy
        let mouy = ev.clientX - rect.x- centx

        let dx = (moux / wid) * rfactor
        let dy = (mouy / hei) * rfactor

        setXAxis(dx)
        setYAxis(dy)
    }

    const validateCode = async () => {
        if (val === "") return;

        let invalid = () => {
            disableTextBox(!disabled);

            setVal('???');

            setTimeout(() => {
                setVal("")
                disableTextBox(false)
            }, 5 * 1000);
        }

        try {
            const request = await ValidateCode(val)
            
            if (!request) return invalid();

            if (!("valid" in request)) return invalid();

            disableTextBox(!disabled);

            setVal('Welcome.');

            return setRenderIt(!renderIt);
        } catch (error) {
            return invalid();
        }
    }

    return !renderIt ? (
        <motion.div 
            style={{
                perspective: "1200px",
                transformStyle: "preserve-3d",
            }}
            transition={sp}
            className="h-screen md:w-screen overflow-y-hidden flex flex-col bg-primarylight"
        >
            <motion.div 
                transition={sp}
                ref={ref}
                onMouseMove={handle} 
                onMouseLeave={() => { setXAxis(0);setYAxis(0)}}
                className="flex items-center flex-col space-y-5 justify-center my-auto"
            >
                <div className="md:hidden text-center">
                    <img src="/images/minilogo.png" alt="Logo" />
                </div>

                <motion.div 
                    className="md:block hidden" 
                    transition={sp}
                    whileHover={{ scale: 1.1 }} 
                    style={{ rotateX: x, rotateY: y }}
                >
                    <div style={{ perspective: "1200px", transformStyle: "preserve-3d", width: "100%", height: "100%" }}>
                        <img src="/images/rbxlogo.png" alt="Logo" className="md:w-[150px]" />
                    </div>
                </motion.div>

                <div className="flex flex-col space-y-2 items-center">
                    <h1 className="lg:text-2xl text-lg text-wrap w-fit z-10 text-white font-bold text-center">
                        Are you ready?
                    </h1>

                    <form onSubmit={(e) => {e.preventDefault(); validateCode()}} >
                        <input 
                            type="text" 
                            placeholder="..."
                            disabled={disabled}
                            value={val}
                            maxLength={17}
                            onChange={(e) => { console.log(e.target.value);if (e.target.value.length === 17) { setVal(e.target.value); validateCode() } else setVal(e.target.value) }}
                            className="flex text-center h-9 w-full rounded-md border border-input border-primarydark focus-visible:outline-none bg-primary text-white px-3 py-1 text-sm shadow-sm transition-colors placeholder:text-muted-foreground focus" 
                        />
                    </form>
                </div>
            </motion.div>
            
            <div className="flex [&_span_img]:w-16 z-10 text-center items-center justify-center">
                <motion.span
                    transition={sp}
                    whileHover={{ scale: 1.5 }} >
                    <img src="/images/Discord.svg" alt="DISCORD" />
                </motion.span>
                <motion.span
                    transition={sp}
                    whileHover={{ scale: 1.5 }} >
                    <img src="/images/Twitter.svg" alt="TWITTER" />
                </motion.span>
            </div>
        </motion.div>
    ) : children
}