import { withAuth } from "../../utils/api";
import { ReduxAuthStates } from "../types";

const initialState = {
    token: localStorage.getItem("token"),
    isAuthenticated: false,
    isLoading: true,
    user: null,
};
  
const auth = (state = initialState, action: any) => {
    const { type, payload } = action;

    switch (type) {
        case ReduxAuthStates.LOADING:
            return {
                ...state,
                isLoading: true,
                isAuthenticated: false,
            };
        case ReduxAuthStates.UPDATE: {
            return {
                ...state,
                ...payload
            }
        }
        case ReduxAuthStates.LOGIN:
            localStorage.setItem("token", payload.token);

            withAuth(payload.token, () => null);

            return {
                ...state,
                ...payload,
                isLoading: false,
                isAuthenticated: true,
            };
        case ReduxAuthStates.LOGOUT:
            localStorage.removeItem("token");

            return {
                ...state,
                user: null,
                token: null,
                isLoading: true,
                isAuthenticated: false,
            };
        default:
            return state;
    }
};


export default auth;