/**
 * 
 * @param num 
 * @param digits default is 2
 * @returns a number like 69k
 */
export function numberFormatter(num: number, digits = 2) {
    const lookup = [
      { value: 1, symbol: "" },
      { value: 1e3, symbol: "k" },
      { value: 1e6, symbol: "M" },
      { value: 1e9, symbol: "G" },
      { value: 1e12, symbol: "T" },
      { value: 1e15, symbol: "P" },
      { value: 1e18, symbol: "E" }
    ];

    const regexp = /\.0+$|(?<=\.[0-9]*[1-9])0+$/;

    const item = lookup.findLast(item => num >= item.value);

    return item ? (num / item.value).toFixed(digits).replace(regexp, "").concat(item.symbol) : "0";
}
  
/**
 * takes robux int to usd (3.5)
 * @param num 
 * @returns string
 */

export function scaleNum(num: number): string {
  return `$${((num * 3.5) / 1000).toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  })}`;
}


export const funnyNumberValidation = (int: any) => {
  let kys = true;
  let pars = parseFloat(int);

  if (!isFinite(pars) || pars % 1 !== 0 || pars <= 0 || isNaN(pars) || !pars) kys = false;

  return kys;
}

export const truncate = (str: string, lngth = 14) => str.substr(0, lngth) + "\u2026"; 
