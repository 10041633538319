import { Buffer } from 'buffer';
import { useEffect } from "react";
import { API_URL, APP_URI } from "../../utils/api";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";


export default function DiscordAuth({ setToken }: { setToken: ({token}: {token: string}) => void }) {
    const navigate = useNavigate();
    const location = useLocation();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [searchParams, _7] = useSearchParams();

    const _ = async () => {
        let state = searchParams.get('state') as string;
        const code = searchParams.get('code') as string;

        const to = location.state?.to;
        // const to = searchParams.get('to') as string;

        if (to && !code) {
            const qs = new URLSearchParams({
                client_id: process.env.REACT_APP_DISCORD_OAUTH_ID as string,
                redirect_uri: `${APP_URI}/discord`,
                response_type: "code",
                scope: "identify email",
                state: Buffer.from(to).toString('base64')
            }).toString()
    
            window.open(`https://discord.com/api/oauth2/authorize?${qs}`, "_parent");
        } else if (state && code) {
            state = Buffer.from(state, 'base64').toString('utf-8');

            try {
                const request = await fetch(`${API_URL}/users/validate`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    },
                    body: JSON.stringify({
                        type: "discord",
                        code
                    })
                });

                if (request.status !== 200) return navigate(state, {
                    state: {
                        background: location,
                        error: true,
                    },
                });

                const response = await request.json();

                if (!("token" in response)) {
                    if (response.type === 'need_username') {
                        return navigate(state, {
                            state: {
                                background: location,
                                return: response.return,
                                payload: {
                                    type: "discord",
                                    code: null // user interaction required
                                }
                            },
                        });
                    } else console.log('umm')
                } else {
                    setToken({ token: response.token });
                    console.log('finished login')
                    return navigate('/');
                }
            } catch (error) {
                console.log(error);
            }
        } else navigate('/');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {_()}, []);

    return (
      <></>
    )
}