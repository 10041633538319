
import toast from "react-hot-toast";
import { connect } from "react-redux";
import { merge } from "../../utils/tw";
import { scaleNum } from "../../utils/string";
import DuelCard from "../DuelCard/DuelCard";
import { Fragment, useEffect, useMemo, useState, useReducer } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { AnimatedSprite, Container, Stage } from "@pixi/react";
import {
  Link,
  Navigate,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { API_URL, drops, getBatchImages } from "../../utils/api";

import moment from "moment";
import { Helmet } from 'react-helmet'

export default function Marketplace({ user, token, isAuthenticated }: any) {
  const location = useLocation();
  const navigate = useNavigate();

  const [filter, setFilter] = useState<'h2l' | 'l2h' | 'r'>('h2l');

  const filters = [
    'Hats', 'Faces', 'Gear', 'Hair'
  ]

  const listings = Array.from({ length: 40 }).map(() => (
    <div className="w-[270px] h-[190px] bg-primaryextra rounded-3xl px-4 py-3 space-y-3 text-white hover:border-primarydark border-2 border-primaryextra border-double">
        <div className="h-[120px] items-center flex justify-center">
            <img src="/images/legit.png" className="p-3 h-full scale-[1.5]" />
        </div>

        <div className="h-fit">
            <p className="text-[#FFB800] space-x-1 text-xs ">
                $
                {' '}
                <span className="text-white">
                    5,400
                </span>
                {' '}
                +
                <span className="text-[#EBDA80]">
                    600
                </span>
                {' '}
                =
                <span className="text-white">
                    6,000
                </span>
            </p>
            <p className="text-sm font-medium">
                Perfectly Legitimate Business Hat
            </p>
        </div>
    </div>
  ))

  return (
    <div className="w-full my-8 space-y-4 sm:ml-4 ml-0 md:ml-0 max-md:flex-col">
        <div className="w-11/12 h-[150px] flex items-center justify-center max-md:mx-6 bg-white">
            <img src="/images/lelouchBanner.png" className="w-full h-full" />
        </div>

        <div className="w-full flex h-20 text-white items-center md:space-x-6 max-md:flex-col max-md:h-[220px] max-md:space-y-2 max-md:justify-center">
            <div className="sm:w-[560px] md:w-[500px] w-[350px] flex rounded-2xl px-6 py-3 bg-primarym text-sm space-x-2">
                <input placeholder="Search for items..." className="bg-primarym text-sm w-full" />

                <button className="font-light px-3 py-2.5 bg-primarym-light rounded-lg w-56 text-sm" onClick={() => setFilter((p) => p === 'h2l' ? 'l2h' : p === 'l2h' ? 'r' : 'h2l')}>
                    Filter: <span className="font-bold">{filter === 'h2l' ? 'High to low' : filter === 'l2h' ? 'Low to high' : 'Recent'}</span>
                </button>
            </div>
            <ul className="max-sm:w-[350px] max-md:w-[560px] bg-primarym text-sm [&>li]:flex justify-center flex flex-row md:gap-3 gap-2 sm:gap-10 items-center rounded-2xl px-6 h-16 overflow-y-hidden scroll-smooth 'this is brainrot'">
                {filters.map((b, _) => (<li key={_} className="rounded-xl bg-primarym-contrast py-2 px-4 my-auto mt-3.5">{b}</li>))}
            </ul>
            <p className="w-38 rounded-2xl px-6 py-5 bg-primarym text-sm max-md:hidden block">
                56 Limiteds Listed
            </p>
            <p className="w-26 rounded-2xl px-6 py-5 bg-primarym text-sm max-md:hidden block">
                <span className="font-semibold text-darkgold">
                    $1M
                </span>
                {' '}
                Sold
            </p>
            {/* <div className="max-sm:hidden max-md:flex space-x-2">
                <p className="w-38 rounded-2xl px-6 py-5 bg-primarym text-sm">
                    56 Limiteds Listed
                </p>
                <p className="w-26 rounded-2xl px-6 py-5 bg-primarym text-sm ">
                    <span className="font-semibold text-darkgold">
                        $1M
                    </span>
                    {' '}
                    Sold
                </p>
            </div> */}

            <button className="rounded-2xl px-12 py-5 bg-primarym-light text-sm max-md:w-[560px] max-sm:w-[350px]">
                Sell Items
            </button>
        </div>

        <div className="w-11/12 h-full overflow-y-hidden grid xl:grid-cols-5 gap-2 sm:grid-cols-2 lg:grid-cols-3 mx-[15%] sm:mx-0 grid-cols-none">
            {listings}
        </div>
    </div>
  );
}
