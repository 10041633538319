import { ReduxGlobalStates } from "../types";

const initialState = {
    isChatOpen: true
};
  
const global_state = (state = initialState, action: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { type, payload } = action;

    switch (type) {
        case ReduxGlobalStates.TOGGLE_CHAT:
            localStorage.setItem("isChatOpen", String(!state.isChatOpen));

            return {
                ...state,
                isChatOpen: !state.isChatOpen,
            };
        default:
            return state;
    }
};


export default global_state;